import { computed } from 'vue';
import store from '@/store';

// import { DEFAULT_MARKET_BSV_USD } from '@/config/constants';
// import config from '@/config';

import bsvPriceMain from '../compositions/bsvPrice';
// import { floatList } from '@/helpers/floatList';
import { toFixedAmount } from '@/helpers/utils';

import {
  getFormatedAmount,
  removeSpaces,
  // toCurrencyFormat,
} from '@/helpers/utils';
import { POSITION_OPEN_MIN_VAL } from '@/helpers/enums';
// import { userProviders } from '@/modules/user/api';

const staticFee = 7e-6; // 700 sat for subsequent txs, and 300 sat for current tx fee

export default function useMaxBalance(
  leverage,
  _,
  fiorinMode,
  t,
  amountStepFlag
) {
  // const activeConnect = computed(
  //   () => store.getters['connectors/activeConnect']
  // );

  // const marketsById = computed(() => store.getters['markets/marketsByName']);
  // const bsvPrice = computed(() => {
  //   const market = marketsById.value[DEFAULT_MARKET_BSV_USD];

  //   return (market && market.usdPrice) || 1;
  // });

  const activeMarket = computed(() => {
    return store.getters['markets/activeMarket'];
  });

  const getAccountBalance = computed(
    () => store.getters['user/getAccountBalance']
  );

  const marketSumAmount = computed(() =>
    store.getters['positions/getMarketMaxAmount'](activeMarket.value.name)
  );

  const maxBalance = computed(() => {
    const fee = 0.0001;

    const maxAmountValue = activeMarket.value.max_amount_usd;
    // console.log('calc max maxAmountValue', maxAmountValue);
    // console.log('calc max marketSumAmount', marketSumAmount.value);
    // console.log(
    //   'calc max getAccountBalance.value.available',
    //   getAccountBalance.value.available
    // );
    // console.log(
    //   'calc max getAccountBalance.value.bounty',
    //   getAccountBalance.value.bounty
    // );

    let minVal = toFixedAmount(
      getFormatedAmount(
        Math.min(
          maxAmountValue - marketSumAmount.value,
          Math.max(
            Math.max(
              getAccountBalance.value.available,
              getAccountBalance.value.bounty
            ) -
              fee -
              staticFee,
            0
          ) *
            (leverage || 1) *
            (fiorinMode ? 1 : 1) // : bsvPrice.value
        ),
        false
      )
    );

    const parsedValue = removeSpaces(minVal + '');

    if (parsedValue.includes('−') || parsedValue.includes('-')) {
      return '0';
    }

    return removeSpaces(minVal);
  });

  const halfBalance = computed(() => {
    if (+maxBalance.value) {
      return toFixedAmount(maxBalance.value / 2);
    }
    return 0;
  });

  const quarterBalance = computed(() => {
    if (+maxBalance.value) {
      return toFixedAmount(maxBalance.value / 4);
    }
    return 0;
  });

  const maxWalletValue = computed(() => {
    return (
      Math.max(
        getAccountBalance.value.available,
        getAccountBalance.value.bounty
      ) * bsvPriceMain()
    );
  });

  const enableToOpenPosition = computed(() => {
    const minVal = maxBalance.value;

    if (maxWalletValue.value < POSITION_OPEN_MIN_VAL) {
      return false;
    }

    return +minVal !== 0;
  });

  const amountTip = computed(() => {
    if (!activeMarket.value) {
      return '';
    }

    if (amountStepFlag === 2) {
      // return t('order.new.titles.half');
      const val = (+halfBalance.value + '').replace(/ /g, '');
      if (val <= 0) {
        return t('popups.marketInactive.limitReached');
      }

      return `${t('order.new.titles.half')} ${
        (+halfBalance.value + '').replace(/ /g, '') <= 0
          ? t('popups.marketInactive.limitReached')
          : halfBalance.value
      }`;
    }

    if (amountStepFlag === 3) {
      // return t('order.new.titles.quarter');
      const val = (+quarterBalance.value + '').replace(/ /g, '');
      if (val <= 0) {
        return t('popups.marketInactive.limitReached');
      }

      return `${t('order.new.titles.quarter')} ${
        (+quarterBalance.value + '').replace(/ /g, '') <= 0
          ? t('popups.marketInactive.limitReached')
          : quarterBalance.value
      }`;
    }

    const maxAmountValue = activeMarket.value.max_amount_usd;

    if (!+maxAmountValue) {
      return t('popups.marketInactive.limitReached');
    }

    // if (amount && amount.length) {
    //   return `${
    //     toCurrencyFormat(
    //       amount /
    //         (activeConnect.value.provider === userProviders.fiorin
    //           ? 1
    //           : bsvPrice.value),
    //       null,
    //       fiorinMode ? 2 : 5
    //     ) || 0
    //   } ${fiorinMode ? 'USD' : 'BSV'}`;
    // }

    const minVal = maxBalance.value;

    if (maxWalletValue.value < POSITION_OPEN_MIN_VAL) {
      return t('popups.marketInactive.walletDepleted');
    }

    if (+minVal === 0) {
      return t('popups.marketInactive.limitReached');
    }
    return `${t('order.new.titles.max')} ${
      +minVal.replace(/ /g, '') < 0
        ? t('popups.marketInactive.limitReached')
        : minVal
    }`;
  });

  return {
    maxBalance,
    halfBalance,
    quarterBalance,
    amountTip,
    maxWalletValue,
    enableToOpenPosition,
  };
}
