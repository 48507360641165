import { getErrorMessage, logMessage } from './helpers';
var QuotesProvider = /** @class */ (function () {
  function QuotesProvider(datafeedUrl, requester) {
    this._datafeedUrl = datafeedUrl;
    this._requester = requester;
  }
  QuotesProvider.prototype.getQuotes = function (symbols) {
    var _this = this;
    return new Promise(function (resolve, reject) {
      _this._requester
        .sendRequest(_this._datafeedUrl, 'quotes', { symbols: symbols })
        .then(function (response) {
          if (response.s === 'ok') {
            resolve(response.d);
          } else {
            reject(response.errmsg);
          }
        })
        .catch(function (error) {
          var errorMessage = getErrorMessage(error);
          logMessage('QuotesProvider: getQuotes failed, error=' + errorMessage);
          reject('network error: ' + errorMessage);
        });
    });
  };
  return QuotesProvider;
})();
export { QuotesProvider };
