<template>
  <div
    class="form-position-confirm"
    :class="{
      hasLoyalty: +loyaltyFeeRate && loyaltyReset,
      hasEstPl: !formData.entryPrice,
      maxForm: +loyaltyFeeRate && loyaltyReset && !formData.entryPrice,
    }"
  >
    <!-- <div class="form-position-confirm__description">
      {{ $t('popups.orderConfirm.q1', { type: orderType }) }}
    </div> -->
    <ItemLabelValue
      :label="$t('order.card.titles.amount')"
      :value="formData.amountBtc + ' USD'"
    />
    <ItemLabelValue
      :label="$t('order.card.titles.entryPrice')"
      :value="`${prefixEntry} ${toCurrencyFormat(
        entryPrice,
        null,
        floatList[activeMarket.name]
      )}`"
    />
    <ItemLabelValue
      v-if="!formData.entryPrice"
      :label="$t('order.card.titles.estPl')"
      :is-red="plAmount < 0"
      :is-green="plAmount > 0"
      :value="plValue"
    />
    <ItemLabelValue
      :label="$t('order.card.titles.leverage')"
      :value="formData.leverage + 'x'"
    />
    <ItemLabelValue
      :label="
        $t('order.card.titles.margin') +
        (isBounty && accountBalance.bounty >= formData.marginValue
          ? ' (bounty)'
          : '')
      "
      :value="marginAmount"
    />
    <ItemLabelValue
      :label="$t('order.card.titles.liquidationPrice')"
      :value="`${prefixLiquidation} ${toCurrencyFormat(
        liquidationPrice,
        null,
        floatList[activeMarket.name]
      )}`"
    />
    <ItemLabelValue
      v-if="+loyaltyFeeRate && loyaltyReset"
      :label="$t('blocks.titles.loyaltyFee')"
      :hint-options="{ top: '-55px', left: '100px' }"
      :hint-title="$t('blocks.titles.loyaltyFeeHint')"
      :value="`${loyaltyFeeRate.toFixed(2)}%`"
    />
    <div
      v-if="
        +loyaltyFeeRate &&
        loyaltyReset &&
        !settings.doNotShowLiquidityFeeWarning
      "
      v-html="$t('order.card.titles.liqFeeHint')"
      class="loyalty-hint"
    />
    <div v-if="config && config.margin_pool_account" class="payment-container">
      <FormPositionConfirmPayment
        :form-data="formData"
        :type="orderType"
        :is-bounty="isBounty"
        :to="config.margin_pool_account"
        :margin-value-custom="marginValueCustom"
        @payment="emit('payment')"
        @error="emit('error')"
        @close="emit('onCloseOrderConfirm')"
      />
    </div>
  </div>
</template>
<script>
import ItemLabelValue from '@/components/ui/ItemLabelValue.vue';
import FormPositionConfirmPayment from './FormPositionConfirmPayment.vue';
import {
  toCurrencyFormat,
  toFixedAmount,
  usdFormatWithPercent,
  toUSDMarginFormat,
} from '@/helpers/utils';
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { PositionSides } from '@/config/constants';
import bsvPrice from '@/compositions/bsvPrice';
import { floatList } from '@/helpers/floatList';
import useCalcPl from '@/compositions/useCalcPl';
import { useMarketPriceForMargin } from '@/compositions/useMarketPriceForMargin';

export default {
  name: 'FormPositionConfirm',
  components: { ItemLabelValue, FormPositionConfirmPayment },
  props: {
    activeMarket: { required: true },
    orderType: { required: true },
    formData: { required: true },
    isBounty: Boolean,
    marginValueCustom: {
      required: true,
    },
  },
  emits: ['payment', 'error', 'onCloseOrderConfirm'],
  setup(props, { emit }) {
    const loyaltyReset = ref(true);
    const store = useStore();
    const { pl, pl_percent } = useCalcPl(
      props.orderType.toLowerCase(),
      props.activeMarket,
      props.formData.amountBtc,
      props.formData.entryPrice,
      props.formData.leverage
    );
    const { marketPriceFromFiorinCurrency } = useMarketPriceForMargin();

    const activeConnect = computed(
      () => store.getters['connectors/activeConnect']
    );

    const prefixEntry = computed(() =>
      props.orderType.toLowerCase() === PositionSides.BUY ? 'ASK' : 'BID'
    );
    const prefixLiquidation = computed(() =>
      props.orderType.toLowerCase() === PositionSides.BUY ? 'BID' : 'ASK'
    );

    const authInfo = computed(() => store.getters['user/truedexAuth']);

    const settings = computed(() => {
      return store.getters['settings/settings'];
    });

    const plAmount = computed(() => pl.value);

    const plValue = computed(() => {
      return usdFormatWithPercent(pl.value, pl_percent.value);
    });

    onMounted(async () => {
      // loyaltyReset.value = true;
      if (authInfo?.value?.userId) {
        await store.dispatch(
          'volumeLimits/fetchLoyaltyFeeRate',
          props.activeMarket.id
        );
        await store.dispatch(
          'volumeLimits/fetchLoyaltyFeeInfo',
          props.activeMarket.id
        );
      }
      // loyaltyReset.value = true;
    });

    const markets = computed(() => store.getters['markets/markets']);

    const loyaltyFeeInfo = computed(
      () => store.getters['volumeLimits/loyaltyFeeInfo']
    );

    const loyaltyFeeRate = computed(
      () => store.getters['volumeLimits/loyaltyFeeRate']
    );

    const liquidationPrice = computed(() => {
      return props.orderType.toLowerCase() === PositionSides.BUY
        ? (props.formData.entryPrice || props.activeMarket.usdPrice) *
            (1 -
              80 / props.formData.leverage / 100 +
              0 / props.formData.amountBtc / bsvPrice())
        : (props.formData.entryPrice || props.activeMarket.usdPrice) *
            (1 +
              80 / props.formData.leverage / 100 -
              0 / props.formData.amountBtc / bsvPrice());
    });

    const entryPrice = computed(() => {
      if (props.formData.entryPrice) {
        return props.formData.entryPrice;
      }

      const current = markets.value.find(
        (item) =>
          `${item.ticker}/${item.base}` ===
          `${props.activeMarket.ticker}/${props.activeMarket.base}`
      );
      return props.orderType === PositionSides.SELL
        ? current.sell
        : current.buy;
    });

    const fiorinMode = computed(() => store.getters['burger/fiorinModeFlag']);

    const marginFiorinValue = computed(() => {
      const margin =
        +props.formData.amountBtc.replace(/ /g, '') /
        `${props.formData.leverage}`.replace(/ /g, '');
      return toUSDMarginFormat(margin, 2);
    });

    const marginFromTokenId = computed(() => {
      const bountyPos =
        props.isBounty &&
        accountBalance.value.bounty >= props.formData.marginValue;

      if (bountyPos) {
        return marginFiorinValue.value;
      }
      return marginFiorinValue.value / marketPriceFromFiorinCurrency.value;
    });

    const marginAmount = computed(() => {
      if (
        !props.formData ||
        !props.formData.amountBtc ||
        !props.formData.leverage ||
        !+props.formData.leverage
      ) {
        return '';
      }
      const marginBSV =
        +props.formData.amountBtc.replace(/ /g, '') /
        (fiorinMode.value ? 1 : bsvPrice()) /
        +`${props.formData.leverage}`.replace(/ /g, '');

      console.log('REFILL LOG margin after *', marginFromTokenId.value);

      const bountyPos =
        props.isBounty &&
        accountBalance.value.bounty >= props.formData.marginValue;

      if (fiorinMode.value) {
        return (
          toFixedAmount(toCurrencyFormat(marginFromTokenId.value, null, null)) +
          ` ${bountyPos ? 'USD' : activeConnect.value?.tokenIdName}`
        );
      }

      return (
        toCurrencyFormat(marginBSV) +
        ` ${fiorinMode.value ? 'USD' : 'BSV'}` +
        ` ($${toCurrencyFormat(bsvPrice() * marginBSV, null, 2)})`
      );
    });

    const config = computed(() => store.getters['user/wsConfig']);
    const accountBalance = computed(
      () => store.getters['user/getAccountBalance']
    );

    return {
      settings,
      liquidationPrice,
      toCurrencyFormat,
      entryPrice,
      marginAmount,
      config,
      emit,
      loyaltyFeeInfo,
      loyaltyFeeRate,
      accountBalance,
      floatList,
      pl,
      pl_percent,
      plValue,
      plAmount,
      loyaltyReset,
      prefixEntry,
      prefixLiquidation,
    };
  },
};
</script>
<style lang="scss" scoped>
.form-position-confirm {
  display: flex;
  flex-direction: column;
  min-height: 226px;

  .loyalty-hint {
    margin: 7px 0;
    font-family: 'Gotham_Pro_Regular';
  }

  &.hasLoyalty {
    min-height: 379px;
  }

  &.hasEstPl {
    min-height: 270px;
  }

  &.maxForm {
    min-height: 370px;
  }

  &__description {
    margin: 15px 0;
    font-family: Gotham_Pro_Medium;
  }
}
</style>
