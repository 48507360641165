import { connMarkets } from '@/helpers/signalR';
import { logMessage } from './helpers';
var Requester = /** @class */ (function () {
  function Requester(headers) {
    if (headers) {
      this._headers = headers;
    }
  }
  Requester.prototype.sendRequest = function (datafeedUrl, urlPath, params) {
    const method = `udf/${urlPath}`;
    const opts = params ? [method, params] : [method];

    logMessage('New request: %j', opts);

    return connMarkets.invoke.apply(connMarkets, opts);
  };
  return Requester;
})();
export { Requester };
