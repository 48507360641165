const types = {
  CHANGE_GRAPH_TYPE: 'CHANGE_GRAPH_TYPE',
  SET_REDRAW_LINES: 'SET_REDRAW_LINES',
  SET_RELOAD_FLAG: 'SET_RELOAD_FLAG',
  SET_GRAPH_VISIBILITY: 'SET_GRAPH_VISIBILITY',
};

const getInitialState = () => ({
  currentGraphType: 'MID',
  redrawLines: false,
  reloadGraphFlag: false,
  isGraphVisible: false,
});

const getters = {
  getCurrentGraphType: (state) => state.currentGraphType,
  redrawLines: (state) => state.redrawLines,
  reloadGraphFlag: (state) => state.reloadGraphFlag,
  isGraphVisible: (state) => state.isGraphVisible,
};

const mutations = {
  [types.SET_RELOAD_FLAG](state, value) {
    state.reloadGraphFlag = value;
  },
  [types.CHANGE_GRAPH_TYPE](state, type) {
    state.currentGraphType = type;
  },
  [types.SET_REDRAW_LINES](state, value) {
    state.redrawLines = value;
  },
  [types.SET_GRAPH_VISIBILITY](state, value) {
    state.isGraphVisible = value;
  },
};

const actions = {
  changeGraphType({ commit }, type) {
    commit(types.CHANGE_GRAPH_TYPE, type);
  },
  setRedrawLinesFlag({ commit }) {
    commit(types.SET_REDRAW_LINES, true);
  },
  clearRedrawLinesFlag({ commit }) {
    commit(types.SET_REDRAW_LINES, false);
  },
  toggleGraphVisibility({ commit }, value) {
    commit(types.SET_GRAPH_VISIBILITY, value);
  },
};

export default {
  namespaced: true,
  state: getInitialState(),
  mutations,
  actions,
  getters,
};
