import { RISK_MANAGEMENT } from '@/modules/settings/store';
import { useModals } from '@/modules/modals/api';
import notify from '@/plugins/notify';
import { userProviders } from '@/modules/user/api';
import { banCountires } from '@/config/countries';
import router from '@/router';
const FIORIN_HOST = process.env.VUE_APP_FIORIN_HOST;
import { setMetaThemeColor } from '@/helpers/ui';
import { isIOSDxsApp } from '@/helpers/detects';
import { openLandingDXS } from '@/helpers/landing';
import { copyText } from 'vue3-clipboard';

const state = {
  fiorinMode: false,
  toggleProTrading: false,
  languagesMap: {
    english: 'en',
    chinese: 'cn',
    spanish: 'es',
    japanese: 'jp',
  },
  themes: ['light', 'dark'],
  isBurgerOpened: false,
  ...useModals(),
};

const getters = {
  confirmRemovePositions: () => ({
    translate: true,
    title: 'header.menuItems.closePositions',
    action: 'confirmRemovePositions',
  }),
  fiorinModeFlag: (state, getters, rootState, rootGetters) => {
    const activeConnect = rootGetters['connectors/activeConnect'];

    return (
      state.fiorinMode &&
      (!activeConnect.provider ||
        activeConnect.provider === userProviders.fiorin)
    );
  },
  isBurgerOpened: (state) => state.isBurgerOpened,
  toggleProTrading: (state) => state.toggleProTrading,
  account: (state, getters, rootState, rootGetters) => {
    const activeConnect = rootGetters['connectors/activeConnect'];

    let items = [
      // getters.showTour,
      getters.history,
      getters.features,
      getters.email, // index 4 for remove
      getters.phoneVerification,
      getters.verification,
      getters.updateMarkets,
      // getters.closeAllPositions,
      getters.proTrading,
      getters.minimizeTool,
      getters.toggleLeverage,
      getters.risk,
      getters.profitRecovery,
      getters.accountDeletion,
    ];

    return {
      nested: true,
      translate: true,
      title: 'mobile.menuItems.account',
      nestedItems: items.filter((w, ndx) =>
        activeConnect.provider === userProviders.fiorin
          ? ndx === 100 // 4
            ? false
            : true
          : !w.isFiorin
      ),
    };
  },
  information: (state, getters, rootState, rootGetters) => {
    const activeConnect = rootGetters['connectors/activeConnect'];

    return {
      nested: true,
      translate: true,
      title: 'header.menuItems.information',
      nestedItems: activeConnect.provider
        ? [
            getters.statistics,
            getters.info,
            getters.infoRisk,
            getters.infoAntimoney,
            getters.infoPersons,
            getters.infoPrivacy,
            getters.announcements,
            getters.faq,
            getters.reviews,
            getters.version,
          ]
        : [
            getters.statistics,
            getters.info,
            getters.infoRisk,
            getters.infoAntimoney,
            getters.infoPersons,
            getters.infoPrivacy,
            getters.faq,
            getters.reviews,
            getters.version,
          ],
    };
  },
  switchAccounts: (state, getters, rootState, rootGetters) => {
    const accounts = rootGetters['connectors/connects']
      .map((connect) => {
        let title = '';

        if (connect.fiorinUserName) {
          title = connect.fiorinUserName;
        }

        if (connect.provider === userProviders.handCash) {
          title = (
            ((connect.profile &&
              connect.profile.publicProfile &&
              connect.profile.publicProfile.handle) ||
              '') + '@hc'
          ).toUpperCase();
        }

        if (connect.provider === userProviders.moneyButton) {
          title = (
            ((connect.serverInfo && connect.serverInfo.providerId) || '') +
            '@mb'
          ).toUpperCase();
        }

        if (connect.provider === userProviders.relayX) {
          title = (
            ((connect.serverInfo && connect.serverInfo.providerId) || '') +
            '@rx'
          ).toUpperCase();
        }

        if (connect.provider === userProviders.dotWallet) {
          title = (
            ((connect.userInfo && connect.userInfo.id) || '') + '@dw'
          ).toUpperCase();
        }

        return {
          ...connect,
          title,
          action: 'switchAccount',
        };
      })
      .filter((acc) => acc.title && acc.title.length > 3);

    const usersFromSettings = rootGetters['settings/settings'].groupUsers;

    const userStructure = {};
    accounts.forEach((accountWithProvider) => {
      userStructure[
        accountWithProvider.title.toLowerCase().split('@')[0]
      ] = true;
    });

    const getUserTitle = (login, userName) => {
      if (login.includes('handcash')) {
        return `${userName}@HC`;
      }
      if (login.includes('moneybutton')) {
        return `${userName}@MB`;
      }
      if (login.includes('relay')) {
        return `${userName}@RX`;
      }
      if (login.includes('ddpurse')) {
        return `${userName}@DW`;
      }
      if (login.includes('dxs')) {
        return `${userName}@DXS`;
      }
      if (login.includes('fiorin')) {
        return `${userName}@FIORIN`;
      }
      return '';
    };

    usersFromSettings &&
      usersFromSettings.forEach((user) => {
        const userName = user.login.toLowerCase().split('@')[0];
        const title = getUserTitle(user.login, userName);

        if (!userStructure[userName] && title) {
          accounts.push({
            // check type wallet
            title,
            initialDisabled: true,
            action: 'disableSwitchAccount',
          });
        }
      });

    accounts.unshift({
      title: 'header.menuItems.addAccount',
      action: 'login',
    });

    return {
      nested: true,
      title: 'header.menuItems.switchAccount',
      nestedItems: accounts,
    };
  },
  changeLanguage: (state) => {
    return {
      title: 'header.menuItems.language',
      nestedItems: Object.keys(state.languagesMap).map((language) => ({
        title: `header.menuItems.languages.${language}`,
        action: 'changeLanguage',
      })),
    };
  },
  showShuttedDowmModals: () => ({
    title: 'header.menuItems.showInactiveModals',
    action: 'showAllModals',
  }),
  tradingSession: () => ({
    mobile: true,
    title: 'mobile.menuItems.tradingSession',
    action: 'tradingSession',
  }),
  topUpWallet: () => {
    // const balance = rootGetters['user/getAccountBalance'];
    // mobile: true,
    return {
      title: 'mobile.menuItems.wallet',
      // +balance.available || +balance.bounty
      //   ? 'mobile.menuItems.wallet'
      //   : 'mobile.menuItems.deposit',
      action: 'topUpWallet',
    };
  },
  provideLiquidity: () => ({
    title: 'popups.liquidityFundraiser.title',
    action: 'provideLiquidity',
  }),
  bountyTrading: () => ({
    isFiorin: true,
    title: 'header.menuItems.bounty',
    action: 'bountyTrading',
  }),
  fiorinMode: () => ({
    title: 'Toggle Fiorin Mode',
    action: 'fiorinMode',
  }),
  tradingContests: () => ({
    desktop: true,
    title: 'header.menuItems.tradingContests',
    action: 'tradingContest',
  }),

  watchIntro: () => ({
    title: 'header.menuItems.watchIntro',
    action: 'watchIntro',
  }),

  theme: (state) => ({
    title: 'header.menuItems.switchColor',
    nestedItems: state.themes.map((theme) => ({
      title: `header.menuItems.switchColor.${theme}`,
      action: 'changeTheme',
    })),
  }),
  proTrading: () => ({
    title: 'header.menuItems.togglePro',
    action: 'togglePro',
  }),
  toggleLeverage: () => ({
    title: 'Toggle leverage entry',
    action: 'toggleLeverageEntry',
  }),
  minimizeTool: (state, getters, rootState, rootGetters) => ({
    desktop: true,
    title: rootGetters['settings/uiSetting']('tradeToolMinimize')
      ? 'header.menuItems.showTradingTool'
      : 'header.menuItems.hideTradingTool',
    action: 'toggleTool',
  }),
  risk: (state, getters, rootState, rootGetters) => {
    return {
      title:
        rootGetters['settings/uiSetting']('riskManagement') ===
        RISK_MANAGEMENT.PRICE
          ? 'header.menuItems.riskMgmtPercent'
          : 'header.menuItems.riskMgmtPrice',
      action: 'toggleRisk',
    };
  },
  verification: (state, getters, rootState, rootGetters) => ({
    translate: true,
    title:
      rootGetters['settings/settings'].kycStatus === 'NotStarted'
        ? 'header.menuItems.verification'
        : 'header.menuItems.kycStatus',
    action:
      rootGetters['settings/settings'].kycStatus === 'NotStarted'
        ? 'verification'
        : 'kycStatus',
  }),
  phoneVerification: () => ({
    translate: true,
    title: 'header.menuItems.phoneVerification',
    action: 'phoneVerification',
  }),
  email: () => ({
    translate: true,
    title: 'header.menuItems.email',
    action: 'email',
  }),
  history: () => ({
    translate: true,
    title: 'order.history.exportHistory',
    action: 'export',
  }),
  info: () => ({
    translate: true,
    title: 'header.menuItems.termsConditions',
    action: 'info',
  }),
  infoRisk: () => ({
    translate: true,
    title: 'header.menuItems.risk',
    action: 'infoRisk',
  }),
  infoAntimoney: () => ({
    translate: true,
    title: 'header.menuItems.antimoney',
    action: 'infoAntimoney',
  }),
  infoPersons: () => ({
    translate: true,
    title: 'header.menuItems.persons',
    action: 'infoPersons',
  }),
  infoPrivacy: () => ({
    translate: true,
    title: 'header.menuItems.privacy',
    action: 'infoPrivacy',
  }),
  buyCrypto: () => ({
    translate: true,
    title: 'header.menuItems.buyCrypto',
    action: 'buyCrypto',
  }),
  statistics: () => ({
    translate: true,
    title: 'header.menuItems.statistics',
    action: 'statistics',
  }),
  updateMarkets: () => ({
    translate: true,
    title: 'header.menuItems.updateMarkets',
    action: 'updateMarkets',
  }),
  closeAllPositions: () => ({
    translate: true,
    title: 'header.menuItems.closePositions',
    action: 'closeAllPositions',
  }),
  announcements: () => ({
    translate: true,
    title: 'header.menuItems.announcements',
    action: 'announcements',
  }),
  showTour: () => ({
    translate: true,
    title: 'popups.tour.showTour',
    action: 'showTour',
  }),
  faq: () => ({
    translate: true,
    title: 'header.menuItems.faq',
    action: 'faq',
  }),
  support: () => ({
    translate: true,
    title: 'header.menuItems.support',
    action: 'support',
  }),
  reviews: () => ({
    translate: true,
    title: 'header.menuItems.reviews',
    action: 'reviews',
  }),
  logout: () => ({
    title: 'header.menuItems.logOut',
    action: 'logout',
  }),
  login: () => ({
    title: 'header.menuItems.logIn',
    action: 'login',
  }),
  privacy: () => ({
    title: 'Privacy Policy',
    action: 'privacyPolicy',
  }),
  version: () => ({
    title: 'Last update 12.08 (12:00)',
    action: 'lastUpdate',
  }),
  features: () => ({
    translate: true,
    title: 'popups.buttons.fundFeatures',
    hideInFiorin: true,
    action: 'features',
  }),
  profitRecovery: () => ({
    translate: true,
    title: 'tradingSession.titles.profitRecovery',
    action: 'profitRecovery',
  }),
  accountDeletion: () => ({
    translate: true,
    title: 'tradingSession.titles.accountDeletion',
    action: 'accountDeletion',
  }),
  menuItems: (state, getters, rootState, rootGetters) => {
    const activeConnect = rootGetters['connectors/activeConnect'];
    const profile = activeConnect && activeConnect.profile;
    const serverInfo = activeConnect && activeConnect.serverInfo;
    const userInfo = activeConnect && activeConnect.userInfo;
    const fiorinUserName = activeConnect && activeConnect.fiorinUserName;
    let userName = '';

    if (fiorinUserName) {
      // userName = fiorinUserName?.split('@')[0]?.toUpperCase();
      userName = fiorinUserName.toUpperCase();
    }

    // mb
    if (
      profile &&
      !profile.publicProfile &&
      serverInfo &&
      serverInfo.providerId
    ) {
      // userName = `${serverInfo.providerId.toUpperCase()}`;
      userName = `${serverInfo.providerId.toUpperCase()}@${serverInfo.provider.toUpperCase()}`;
    }

    if (userInfo && userInfo.id) {
      // userName = `${userInfo.id}`;
      userName = `${userInfo.id}@DW`;
    }

    // hc
    if (profile && profile.publicProfile && profile.publicProfile.paymail) {
      const hcuserName =
        profile &&
        profile.publicProfile &&
        profile.publicProfile.paymail.replace('.io', '').toUpperCase();
      userName = hcuserName; // hcuserName?.split('@')[0]?.toUpperCase();
    }
    // const balance = rootGetters['user/getAccountBalance'];
    if (activeConnect.provider) {
      const account = {
        ...getters.account,
        nestedItems: getters.account.nestedItems.filter((item) => {
          return activeConnect.provider === userProviders.fiorin
            ? !item.hideInFiorin
            : true;
        }),
      };

      const liquidity = {
        title: 'header.menuItems.liquidity',
        translate: true,
        mobile: true,
        nestedItems: [getters.tradingSession, getters.provideLiquidity],
      };

      const desktopLiquidity = {
        ...getters.provideLiquidity,
        desktop: true,
      };

      const phoneConfirmed =
        rootGetters['settings/settings']?.phoneNumberConfirmed;

      const bountyProgramAvailable =
        rootGetters['settings/settings']?.bountyProgramAvailable;

      return [
        { action: 'userName', title: userName },
        desktopLiquidity,
        liquidity,
        isIOSDxsApp() ? null : getters.theme,
        getters.changeLanguage,
        account,
        getters.switchAccounts,
        activeConnect.provider === userProviders.fiorin && getters.topUpWallet,
        getters.information,
        phoneConfirmed ? getters.confirmRemovePositions : null,
        activeConnect.provider === userProviders.fiorin &&
          bountyProgramAvailable &&
          getters.bountyTrading,
        isIOSDxsApp() ? null : getters.support,
        getters.logout,
      ].filter((item) => item);
    } else {
      return [
        // getters.tradingSession,
        getters.watchIntro,
        isIOSDxsApp() ? null : getters.theme,
        getters.changeLanguage,
        getters.information,
        isIOSDxsApp() ? null : getters.support,
        // getters.showTour,
        getters.login,
      ].filter((item) => item);
    }
  },
  favouriteItems: (state, getters, rootState, rootGetters) => {
    const favouriteItemProps = rootGetters['settings/menuFavourites'];
    const flatMenuItems = getters.menuItems.concat(
      ...getters.menuItems
        .map((item) => item.nestedItems)
        .filter((item) => item)
    );
    return flatMenuItems.filter(
      (item) =>
        favouriteItemProps.includes(item.action) ||
        favouriteItemProps.includes(item.title)
    );
  },
};

const types = {
  TOGGLE_PRO_TRADING: 'TOGGLE_PRO_TRADING',
  SET_BURGER_STATUS: 'SET_BURGER_STATUS',
};

const mutations = {
  [types.TOGGLE_PRO_TRADING](state) {
    state.toggleProTrading = !state.toggleProTrading;

    const tradingState = state.toggleProTrading ? 'On' : 'Off';
    console.log('toggleProTrading', tradingState);

    notify({ text: `Pro trading: ${tradingState}`, type: 'info' });
  },
  [types.SET_BURGER_STATUS](state, value) {
    state.isBurgerOpened = value;
  },
};

const actions = {
  userName({ rootGetters }) {
    const activeConnect = rootGetters['connectors/activeConnect'];
    const profile = activeConnect && activeConnect.profile;
    const serverInfo = activeConnect && activeConnect.serverInfo;
    const userInfo = activeConnect && activeConnect.userInfo;
    const fiorinUserName = activeConnect && activeConnect.fiorinUserName;
    let userName = '';

    if (fiorinUserName) {
      // userName = fiorinUserName?.split('@')[0]?.toUpperCase();
      userName = fiorinUserName.toUpperCase();
    }

    // mb
    if (
      profile &&
      !profile.publicProfile &&
      serverInfo &&
      serverInfo.providerId
    ) {
      // userName = `${serverInfo.providerId.toUpperCase()}`;
      userName = `${serverInfo.providerId.toUpperCase()}@${serverInfo.provider.toUpperCase()}`;
    }

    if (userInfo && userInfo.id) {
      // userName = `${userInfo.id}`;
      userName = `${userInfo.id}@DW`;
    }

    // hc
    if (profile && profile.publicProfile && profile.publicProfile.paymail) {
      const hcuserName =
        profile &&
        profile.publicProfile &&
        profile.publicProfile.paymail.replace('.io', '').toUpperCase();
      userName = hcuserName; // hcuserName?.split('@')[0]?.toUpperCase();
    }
    copyText(userName);
    notify({ text: `Copied`, type: 'info' });
  },
  async updateMarkets({ state }) {
    state.showModal('UpdateMarkets');
  },
  closeAllPositions({ dispatch }) {
    dispatch('positions/closeAllPositions', null, { root: true });
  },
  privacyPolicy({ dispatch }) {
    dispatch('togglePrivacy');
  },
  lastUpdate() {},
  announcements({ state }) {
    state.showModal(state.modalsByName.announcements);
  },
  confirmRemovePositions({ state }) {
    state.showModal(state.modalsByName.confirmRemovePositions);
  },
  disableSwitchAccount({ state }) {
    // state.showModal(state.modalsByName.preLogin)
    state.showModal(state.modalsByName.login);
  },
  switchAccount({ dispatch, rootGetters }, { value }) {
    let findAccount = null;

    // hc
    if (value.toLowerCase().includes('hc')) {
      findAccount = rootGetters['connectors/connects'].find(
        (acc) => acc.provider === userProviders.handCash
      );
    }
    // mb
    if (value.toLowerCase().includes('mb')) {
      findAccount = rootGetters['connectors/connects'].find(
        (acc) => acc.provider === userProviders.moneyButton
      );
    }

    // rx
    if (value.toLowerCase().includes('rx')) {
      findAccount = rootGetters['connectors/connects'].find(
        (acc) => acc.provider === userProviders.relayX
      );
    }

    // dw
    if (value.toLowerCase().includes('dw')) {
      findAccount = rootGetters['connectors/connects'].find(
        (acc) => acc.provider === userProviders.dotWallet
      );
    }

    // fiorin
    if (
      value.toLowerCase().includes('fiorin') ||
      value.toLowerCase().includes('dxs')
    ) {
      findAccount = rootGetters['connectors/connects'].find(
        (acc) => acc.provider === userProviders.fiorin
      );
      dispatch('burger/fiorinMode', true, { root: true });
    } else {
      dispatch('burger/fiorinMode', false, { root: true });
    }

    if (findAccount) {
      dispatch('connectors/setUserName', '', { root: true });
      dispatch('burger/fiorinMode', false, { root: true });
      dispatch('connectors/disconnect', null, { root: true });
      dispatch('connectors/setActiveConnect', findAccount, { root: true });
      window.location.reload();
    }
  },
  changeLanguage({ state, commit }, { value }) {
    commit(
      'settings/SET_UISETTING',
      {
        k: 'locale',
        v: state.languagesMap[value],
      },
      { root: true }
    );
    console.log(4);
    setTimeout(() => window.location.reload(), 300);
  },
  changeTheme({ state, commit }, { value }) {
    commit(
      'settings/SET_UISETTING',
      {
        k: 'theme',
        v: value,
      },
      { root: true }
    );
    document.body.classList.remove(...state.themes);
    document.body.classList.add(value);
    setMetaThemeColor(value);
  },
  toggleLeverageEntry({ commit, rootGetters }) {
    commit(
      'settings/SET_UISETTING',
      {
        k: 'toggleLeverageEntry',
        v: !rootGetters['settings/uiSetting']('toggleLeverageEntry'),
      },
      { root: true }
    );
    notify({
      text: `Toggle leverage entry: ${
        rootGetters['settings/uiSetting']('toggleLeverageEntry') ? 'on' : 'off'
      }`,
      type: 'info',
    });
  },
  toggleTool({ commit, rootGetters }, offNotify) {
    commit(
      'settings/SET_UISETTING',
      {
        k: 'tradeToolMinimize',
        v: !rootGetters['settings/uiSetting']('tradeToolMinimize'),
      },
      { root: true }
    );
    if (!offNotify) {
      notify({ text: `Settings updated`, type: 'info' });
    }
  },
  toggleRisk({ commit, dispatch, rootGetters }) {
    dispatch('positions/toggleFormPosition', true, { root: true });
    commit(
      'settings/SET_UISETTING',
      {
        k: 'riskManagement',
        v:
          rootGetters['settings/uiSetting']('riskManagement') ===
          RISK_MANAGEMENT.PERCENT
            ? RISK_MANAGEMENT.PRICE
            : RISK_MANAGEMENT.PERCENT,
      },
      { root: true }
    );
    const riskOption =
      rootGetters['settings/uiSetting']('riskManagement') ===
      RISK_MANAGEMENT.PERCENT
        ? 'On'
        : 'Off';

    notify({ text: `Risk manage in %: ${riskOption}`, type: 'info' });
  },
  email({ state }) {
    state.showModal(state.modalsByName.email);
  },
  async verification() {
    state.showModal(state.modalsByName.verification);
  },
  async phoneVerification(_, { auto = false } = {}) {
    if (!auto) {
      await router.push({
        path: router.currentRoute.value.fullPath,
        query: {
          stage: 2,
        },
      });
    }

    state.showModal(state.modalsByName.phoneVerification, true, {
      force: true,
    });
  },
  kycStatus({ state }) {
    state.showModal(state.modalsByName.kycStatus);
  },
  watchIntro() {
    openLandingDXS();
  },
  togglePro({ commit, rootGetters }, { hideToast }) {
    // commit(types.TOGGLE_PRO_TRADING);
    commit(
      'settings/SET_UISETTING',
      {
        k: 'toggleProTrading',
        v: !rootGetters['settings/uiSetting']('toggleProTrading'),
      },
      { root: true }
    );
    console.log('!hideToast', hideToast);
    if (!hideToast) {
      const tradingState = rootGetters['settings/uiSetting']('toggleProTrading')
        ? 'On'
        : 'Off';

      notify({ text: `Pro trading: ${tradingState}`, type: 'info' });
    }
  },
  logout({ dispatch, rootGetters }) {
    const activeConnect = rootGetters['connectors/activeConnect'];

    dispatch('connectors/setUserName', '', { root: true });
    dispatch('connectors/disconnect', null, { root: true });
    dispatch('burger/fiorinMode', false, { root: true });
    localStorage.removeItem('dxsLogined');
    localStorage.removeItem('_fp');

    if (activeConnect.provider === userProviders.fiorin) {
      if (frames.fiorin) {
        dispatch('session/updateInfo', 'USD', { root: true });
        frames.fiorin.postMessage({ event: 'LOGOUT' }, FIORIN_HOST);
      }
    } else {
      console.log(5);
      window.location.reload();
    }
  },
  bountyTrading({ state }) {
    state.showModal(state.modalsByName.bountyTrading);
  },
  fiorinMode({ state }, value) {
    state.fiorinMode = value;
  },
  profitRecovery({ state }) {
    state.showModal(state.modalsByName.profitRecovery);
  },
  accountDeletion({ state }) {
    state.showModal(state.modalsByName.accountDeletion);
  },
  tradingSession({ state }) {
    state.showModal(state.modalsByName.tradingSession);
  },
  topUpWallet({ rootGetters }) {
    const balance = rootGetters['user/getAccountBalance'];
    const FIORIN_HOST = process.env.VUE_APP_FIORIN_HOST;

    if (+balance.available) {
      frames.fiorin.postMessage({ event: 'VIEW:WALLET' }, FIORIN_HOST);
    } else {
      frames.fiorin.postMessage({ event: 'VIEW:DEPOSIT' }, FIORIN_HOST);
    }
  },
  login({ state, rootGetters }) {
    const usaFlag = localStorage.getItem('usa');
    if (
      !usaFlag &&
      banCountires.includes(rootGetters['usa/getLocation']?.countryCode)
    ) {
      state.showModal(state.modalsByName.usaAgree);
      return;
    }
    state.showModal(state.modalsByName.login);
  },
  info() {
    window.open('https://faq.dxs.app/policies/terms-and-conditions', '_blank');
  },
  infoRisk() {
    window.open('https://faq.dxs.app/policies/risk-statement', '_blank');
  },
  infoAntimoney() {
    window.open(
      'https://faq.dxs.app/policies/anti-money-laundering-policy',
      '_blank'
    );
  },
  infoPersons() {
    window.open(
      'https://faq.dxs.app/policies/prohibited-persons-policy',
      '_blank'
    );
  },
  infoPrivacy() {
    window.open('https://faq.dxs.app/policies/privacy-policy', '_blank');
  },
  showTour({ state }) {
    state.showModal(state.modalsByName.tour);
  },
  faq() {
    window.open('https://faq.dxs.app/', '_blank');
  },
  support() {
    window.chaport.on('ready', () => window.chaport.open());
  },
  reviews() {
    window.open('https://www.trustpilot.com/review/www.dxs.app', '_blank');
    // state.showModal(state.modalsByName.reviews);
  },
  // items not in burger
  togglePrivacy({ state }) {
    state.showModal(state.modalsByName.privacyPolicy);
  },
  toggleCookiesPolicy({ state }) {
    state.showModal(state.modalsByName.cookiesPolicy);
  },
  toggleProhibitedPersons() {
    window.open(
      'https://faq.dxs.app/policies/prohibited-persons-policy',
      '_blank'
    );
    // state.showModal(state.modalsByName.prohibitedPersons);
  },
  toggleKYCPolicy({ state }) {
    state.showModal(state.modalsByName.KYC);
  },
  toggleRiskStatement() {
    window.open('https://faq.dxs.app/policies/risk-statement', '_blank');
    // state.showModal(state.modalsByName.riskStatement);
  },
  toggleWhatUsersSay({ dispatch }) {
    dispatch('reviews');
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
