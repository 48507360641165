<template>
  <div :class="{ webPaddings, noBorderBottom }" class="indicator-value">
    <!-- <div :class="{ isRed, isGreen }" class="indicator-value__indicator" /> -->
    <div :class="{ capitalize }" class="indicator-value__title">
      {{ title }}
    </div>
    <icon
      v-if="withClose"
      name="cancel"
      :width="12"
      :height="12"
      class="close"
      @click.stop="$emit('close')"
    />
  </div>
</template>
<script>
import '@/assets/icons/cancel';

export default {
  name: 'IndicatorValue',
  props: {
    title: {
      required: true,
    },
    isRed: {
      type: Boolean,
      default: false,
    },
    isGreen: {
      type: Boolean,
      default: false,
    },
    withClose: {
      type: Boolean,
      default: false,
    },
    capitalize: {
      type: Boolean,
      default: false,
    },
    webPaddings: {
      type: Boolean,
      default: false,
    },
    noBorderBottom: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped>
.indicator-value {
  display: flex;
  align-items: center;
  width: 100%;
  height: 54px;
  padding: 0 20px;
  box-sizing: border-box;
  border-bottom: 1px solid #c3c3c3;
  cursor: pointer;
  position: relative;

  &.noBorderBottom {
    border-bottom: none;
  }

  @media screen and (min-width: 1024px) {
    &.webPaddings {
      padding: 0 40px;
    }
  }

  .close {
    position: absolute;
    right: 20px;
    top: 21px;
    color: #888;
  }

  &__indicator {
    border-radius: 50%;
    width: 10px;
    height: 10px;
    border: 1px solid #a2a2a2;
    margin: 0 20px;

    &.isRed {
      border: 1px solid #de5e56;
      background: #de5e56;
    }

    &.isGreen {
      border: 1px solid #509f96;
      background: #509f96;
    }
  }

  &__title {
    font-size: 15px;
    font-family: Gotham_Pro_Bold;

    &.capitalize {
      text-transform: capitalize;
    }
  }
}

body.dark {
  .indicator-value {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);

    &.noBorderBottom {
      border-bottom: none;
    }

    &__indicator {
      border: 1px solid rgba(255, 255, 255, 0.5);

      &.isRed {
        border: 1px solid #de5e56;
        background: #de5e56;
      }

      &.isGreen {
        border: 1px solid #509f96;
        background: #509f96;
      }
    }

    &:hover {
      .indicator-value__indicator {
        border: 1px solid #fff;
        background: #fff;
      }
    }
  }
}
</style>
