import { updateUserSettings } from './api';
import { FAVOURITES_LIMIT_TO_SHOW } from '@/helpers/enums';
import { setMetaThemeColor } from '@/helpers/ui';
import useTheme from '@/compositions/useTheme';

const types = {
  SET_UISETTING: 'SET_UISETTING',
  SET_SETTING: 'SET_SETTING',
};

const localStoredUISettings = [
  'locale',
  'theme',
  'riskManagement',
  'menuFavourites',
];

export const RISK_MANAGEMENT = {
  PERCENT: 'PERCENT',
  PRICE: 'PRICE',
};

export default {
  namespaced: true,
  state: () => {
    return {
      firstBalanceLoading: true,
      uiSettings: {
        marketFavourites: [],
        menuFavourites: [],
      },
      settings: {},
    };
  },
  getters: {
    firstBalanceLoading: (state) => state.firstBalanceLoading,
    uiSetting: (state) => (key) => state.uiSettings[key],
    uiSettings: (state) => state.uiSettings,
    setting: (state) => (key) => state.settings[key],
    settings: (state) => state.settings,
    menuFavourites: (state) => state.uiSettings.menuFavourites,
    marketFavourites: (state) => state.uiSettings.marketFavourites,
    isFavouriteMarket: (state) => (marketName) =>
      state.uiSettings.marketFavourites.includes(marketName),
  },
  mutations: {
    [types.SET_UISETTING]: (state, { k, v }) => {
      state.uiSettings[k] = v;
      if (localStoredUISettings.includes(k)) {
        localStorage.setItem(
          `tdxp.uiSettings.${k}`,
          typeof v == 'string' ? v : JSON.stringify(v)
        );
      }
      // console.log('set', k, v)
    },
    [types.SET_SETTING]: (state, { k, v }) => {
      state.settings[k] = v;
    },
  },
  actions: {
    setFirstBalanceLoading({ state }, value) {
      state.firstBalanceLoading = value;
    },
    setUserSettings({ commit, state }, settings) {
      const uiSettings = settings.uiSettings
        ? JSON.parse(settings.uiSettings)
        : {};
      delete settings.uiSettings;

      if (!Object.keys(uiSettings).includes('tradeToolMinimize')) {
        uiSettings.tradeToolMinimize = true;
      }

      for (const key of Object.keys(settings)) {
        commit(types.SET_SETTING, { k: key, v: settings[key] });
      }
      for (const key of Object.keys(uiSettings)) {
        // old fav markets : ignore
        if (!key || key[0] === key[0].toUpperCase()) {
          continue;
        }
        // prev fav markets : convert from favourites to marketFavourites
        if (key === 'favourites') {
          const marketFavourites = state.uiSettings.marketFavourites || [];
          for (const base of Object.keys(uiSettings.favourites)) {
            const favsByBase = uiSettings.favourites[base];
            for (const quote of favsByBase) {
              const market = base ? `${quote}/${base}` : quote;
              if (!marketFavourites.includes(market)) {
                marketFavourites.push(market);
              }
            }
          }
          commit(types.SET_UISETTING, {
            k: 'marketFavourites',
            v: marketFavourites,
            noReport: true,
          });
          // all the rest
        } else {
          commit(types.SET_UISETTING, {
            k: key,
            v: uiSettings[key],
            noReport: true,
          });
        }
      }

      if (state.uiSettings.theme) {
        document.body.classList = [state.uiSettings.theme];
      }
    },
    initFromLs({ commit }) {
      commit(types.SET_UISETTING, {
        k: 'locale',
        v: localStorage.getItem('tdxp.uiSettings.locale') || 'en',
        noReport: true,
      });

      const { storeTheme, localStorageTheme } = useTheme();
      const theme = storeTheme.value || localStorageTheme;

      setMetaThemeColor(theme);
      commit(types.SET_UISETTING, {
        k: 'theme',
        v: theme,
        noReport: true,
      });
      document.body.classList = [theme];

      const savedFavoriteMenus = localStorage.getItem(
        'tdxp.uiSettings.menuFavourites'
      );
      if (!savedFavoriteMenus) {
        commit(types.SET_UISETTING, {
          k: 'menuFavourites',
          v: [],
        });
      } else {
        commit(types.SET_UISETTING, {
          k: 'menuFavourites',
          v: JSON.parse(savedFavoriteMenus) || [],
          noReport: true,
        });
      }
      commit(types.SET_UISETTING, {
        k: 'riskManagement',
        v:
          localStorage.getItem('tdxp.uiSettings.riskManagement') ||
          RISK_MANAGEMENT.PRICE,
        noReport: true,
      });
    },
    toggleMenuFavouriteItem({ commit, state }, value) {
      let favs = state.uiSettings.menuFavourites;
      const index = favs.indexOf(value);

      if (typeof value !== 'string') {
        favs = [].concat(value);
        commit(types.SET_UISETTING, { k: 'menuFavourites', v: favs });
        return;
      }

      if (
        !favs.includes(value) &&
        state.uiSettings.menuFavourites.length < FAVOURITES_LIMIT_TO_SHOW
      ) {
        favs.push(value);
      } else {
        favs.splice(index, 1);
      }
      commit(types.SET_UISETTING, { k: 'menuFavourites', v: favs });
    },
    toggleFavouriteMarket({ commit, state }, value) {
      const favs = state.uiSettings.marketFavourites;
      const index = favs.indexOf(value);
      if (index === -1) {
        favs.push(value);
      } else {
        favs.splice(index, 1);
      }

      commit(types.SET_UISETTING, { k: 'marketFavourites', v: favs });
    },
  },
};

export const storePlugin = (store) => {
  store.subscribe(({ type, payload }) => {
    if (
      type === `settings/SET_UISETTING` &&
      !payload.noReport /* && store.getters['user/userId']*/
    ) {
      updateUserSettings({
        uiSettings: JSON.stringify(store.getters['settings/uiSettings']),
      });
    }
  });
};
export const cachedPaths = ['uiSettings.marketFavourites'];
