<template>
  <div :class="{ isWeb }" class="account-stats">
    <div v-if="!isWeb" class="account-stats__userName show">
      {{ !activeConnect?.provider ? 'Demo User' : 'Account' }}
    </div>
    <slot name="title" />
    <div v-if="loading" class="account-loader">
      <Loader />
    </div>
    <template v-else>
      <div class="account-stats__row">
        <InfoBlockVert title="Total Balance, USD" :value="grandTotal" large />
      </div>
      <div class="account-stats__row">
        <InfoBlockVert
          :title="`${accountBalance.unrealizedPL < 0 ? 'Loss' : 'Profit'}, USD`"
          :value="profitLost"
          :is-green="+accountBalance.unrealizedPL >= 0"
          :is-red="+accountBalance.unrealizedPL < 0"
        />
      </div>
      <div class="account-stats__row">
        <InfoBlockVert
          title="Wallet, USD equiv"
          :hint-options="{ top: '-15px', left: '65px' }"
          underline
          :hint-title="
            activeConnect.provider === userProviders.handCash
              ? $t('account.hints.handcashBalance')
              : $t('account.hints.baseBalance')
          "
          :value="balance"
        />
      </div>
      <!-- row -->
      <div class="account-stats__inline">
        <div class="account-stats__row mr15">
          <InfoBlockVert
            title="Risk, USD"
            :value="risk"
            underline
            :hint-options="{ top: '-15px', left: '65px' }"
            :hint-title="$t('account.hints.currentRisk')"
          />
        </div>
        <!-- <div v-if="+sessionInfo.userProfit" class="account-stats__row mr15">
          <InfoBlockVert title="Unsettled, USD" :value="unsettled" />
        </div> -->
        <div
          v-if="
            activeConnect.provider === userProviders.fiorin &&
            +activeConnect.bountyBalance &&
            bountyProgramAvailable
          "
          class="account-stats__row mr15"
        >
          <InfoBlockVert title="Bounty, USD" :value="bountyBalance" />
        </div>
      </div>
      <div v-if="+sessionInfo.userProfit" class="account-stats__row">
        <InfoBlockVert
          title="Unsettled, USD equiv"
          :value="unsettled"
          underline
          :hint-options="{ top: '-15px', left: '65px' }"
          :hint-title="
            $t('account.hints.unsettledBalance', {
              nextSettlement: settlementDynamicValue,
            })
          "
          @mouseover="updateInfo"
        />
      </div>
    </template>
    <!-- favs -->
    <div
      v-if="!loading"
      :class="{ demo: !activeConnect.accessToken, isWeb }"
      class="account-stats__actions"
    >
      <div
        v-for="(item, ndx) in fixedItemsData.concat(
          favouriteItems.filter((item) => !fixedItems.includes(item.action))
        )"
        :key="item.title + item.action"
        class="account-stats__action"
      >
        <IndicatorValue
          :title="buttonTitle(item)"
          :with-close="!fixedItems.includes(item.action)"
          capitalize
          :no-border-bottom="
            ndx ===
            fixedItemsData.concat(
              favouriteItems.filter((item) => !fixedItems.includes(item.action))
            ).length -
              1
          "
          @click="handleMenuItemClick(item)"
          @close="closeFav(item)"
        />
      </div>
      <!-- <div class="account-stats__action">
        <IndicatorValue
          :title="$t('blocks.buttons.hide')"
          class="contest-btn disabled shortcut-btn"
          @mousedown="onChangeTabIndex(0)"
        />
      </div> -->
    </div>
  </div>
</template>
<script>
import '@/assets/icons/cancel';
import IndicatorValue from '@/components/ui/IndicatorValue';
import InfoBlockVert from '@/components/ui/InfoBlockVert';
import { useStore } from 'vuex';
import { computed, onMounted, watch, ref } from 'vue';
import { usdBsvFormatAmount, formatMinutes } from '@/helpers/utils';
import { userProviders } from '@/modules/user/api';
import BigNumber from 'bignumber.js';
import { parseTitle } from '@/helpers/ui';
import { scope } from '@/breakpoints';
import { CONSTANT_ACTIONS } from '@/config/constantActions';
import useUserActions from '@/compositions/useUserActions';
import useChangeFilter from '@/compositions/useChangeFilter';
import { useI18n } from 'vue-i18n';
import Loader from '@/components/ui/Loader';
import DateTime from 'luxon/src/datetime.js';

export default {
  components: {
    IndicatorValue,
    InfoBlockVert,
    Loader,
  },
  props: {
    isWeb: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const updateBlocker = ref(false);
    const settlementDynamicValue = ref('');
    const store = useStore();
    const userActions = useUserActions(props, { emit });
    const { onChangeTabIndex } = useChangeFilter();
    const { t } = useI18n();

    const accountBalance = computed(
      () => store.getters['user/getAccountBalance']
    );

    const sessionInfo = computed(() => store.getters['session/info']);

    const loading = ref(true);

    const activeConnect = computed(
      () => store.getters['connectors/activeConnect']
    );

    const fixedItems = computed(() => {
      if (!activeConnect.value.accessToken) {
        return ['topUpWallet', 'faq', 'support'];
      }
      return ['topUpWallet'];
    });

    const fixedItemsData = computed(() => {
      if (!activeConnect.value.accessToken) {
        return [
          {
            title: 'header.menuItems.faq',
            action: 'faq',
          },
          {
            title: 'header.menuItems.support',
            action: 'support',
          },
        ];
      }
      const arr = [
        {
          action: 'topUpWallet',
          title: 'mobile.menuItems.wallet',
        },
      ];

      return activeConnect.value.provider !== userProviders.fiorin ? [] : arr;
    });

    const firstAppLoaded = computed(
      () => store.getters['localUiSettings/firstAppLoaded']
    );

    if (!activeConnect.value.accessToken || firstAppLoaded.value) {
      loading.value = false;
    }

    const bountyProgramAvailable = computed(() => {
      return store.getters['settings/settings']?.bountyProgramAvailable;
    });

    const balance = computed(() => {
      return accountBalance.value.available
        ? usdBsvFormatAmount(
            accountBalance.value.available || 0,
            '',
            true,
            true,
            false
          )
        : 0;
    });

    const unsettled = computed(() => {
      if (!+sessionInfo.value.userProfit) {
        return 0;
      }
      return usdBsvFormatAmount(
        sessionInfo.value.userProfit,
        '',
        true,
        true,
        false
      );
    });

    const risk = computed(() => {
      if (accountBalance.value.risk === '') {
        return '';
      } else if (!+accountBalance.value.risk) {
        return `0`; // $
      }
      return usdBsvFormatAmount(
        accountBalance.value.risk,
        '',
        true,
        true,
        false
      );
    });

    const profitLost = computed(() => {
      if (accountBalance.value.unrealizedPL === '') {
        return '';
      } else if (!+accountBalance.value.unrealizedPL) {
        return `0`; // $
      }
      return usdBsvFormatAmount(
        accountBalance.value.unrealizedPL,
        '',
        true,
        true,
        false
      );
    });

    const bountyBalance = computed(() => {
      if (!activeConnect.value.bountyBalance) {
        return `0`; // $
      }

      return usdBsvFormatAmount(activeConnect.value.bountyBalance, '', true);
    });

    const grandTotal = computed(() => {
      const value = BigNumber(
        +accountBalance.value.totalWithPL + (+sessionInfo.value.userProfit || 0)
      ).toFixed();

      if (!+value) {
        return `0`;
      }

      return usdBsvFormatAmount(value, '', true, true, false);
    });

    const filterList = (list, topUp = 'topUpWallet') => {
      const item = list.find((item) => item.action === topUp);

      if (!item) {
        return list;
      }

      return [item, ...list.filter((item) => item.action !== topUp)];
    };

    const isMobile = computed(
      () => scope.isSmall || scope.isMedium || scope.noMatch
    );

    const favouriteItems = computed(() => {
      let favs = [];
      if (isMobile.value) {
        favs = filterList(
          store.getters['burger/favouriteItems']
            .filter((item) => {
              return !item.desktop;
            })
            .filter(
              (value, index, self) =>
                index ===
                self.findIndex(
                  (t) => t.action === value.action && t.title === value.title
                )
            )
        );
      } else {
        favs = filterList(
          store.getters['burger/favouriteItems']
            .filter((item) => {
              return !item.mobile;
            })
            .filter(
              (value, index, self) =>
                index ===
                self.findIndex(
                  (t) => t.action === value.action && t.title === value.title
                )
            )
        );
      }

      return favs;
    });

    const settings = computed(() => {
      return store.getters['settings/settings'];
    });

    const buttonTitle = (item) => {
      if (item.action === CONSTANT_ACTIONS.PROVIDE_LIQUIDITY) {
        return settings.value?.providedLiquidity
          ? t('popups.liquidityFundraiser.title2')
          : t('popups.liquidityFundraiser.title');
      }

      return t(item.title);
    };

    const handleMenuItemClick = (item) => {
      if (item.action === CONSTANT_ACTIONS.PROVIDE_LIQUIDITY) {
        userActions.openLiquidity();
      } else if (item.action === CONSTANT_ACTIONS.EXPORT) {
        userActions.exportHistory();
      } else if (item.action === CONSTANT_ACTIONS.FEATURES) {
        userActions.showCrowdfundingModal();
      } else if (item.action === CONSTANT_ACTIONS.BUY_CRYPTO) {
        userActions.openCryptoTab();
      } else if (item.action === CONSTANT_ACTIONS.STATISTICS) {
        userActions.openStats();
      } else {
        const value = item.title.split('.')[item.title.split('.').length - 1];
        store.dispatch(`burger/${item.action}`, { value });
      }
    };

    const userPaymail = computed(() => {
      const profile = activeConnect.value && activeConnect.value.profile;
      const serverInfo = activeConnect.value && activeConnect.value.serverInfo;
      const userInfo = activeConnect.value && activeConnect.value.userInfo;
      const fiorinUserName =
        activeConnect.value && activeConnect.value.fiorinUserName;

      if (fiorinUserName) {
        return fiorinUserName?.split('@')[0]?.toUpperCase();
        // return fiorinUserName.toUpperCase();
      }

      // mb
      if (
        profile &&
        !profile.publicProfile &&
        serverInfo &&
        serverInfo.providerId
      ) {
        return `${serverInfo.providerId.toUpperCase()}`;
        // return `${serverInfo.providerId.toUpperCase()}@${serverInfo.provider.toUpperCase()}`;
      }

      if (userInfo && userInfo.id) {
        return `${userInfo.id}`;
        // return `${userInfo.id}@DW`;
      }

      // hc
      const hcuserName =
        profile &&
        profile.publicProfile &&
        profile.publicProfile.paymail.replace('.io', '').toUpperCase();
      return hcuserName?.split('@')[0]?.toUpperCase();
    });

    watch(
      () => userPaymail.value,
      (userPaymail) => {
        if (userPaymail) {
          store.dispatch('connectors/setUserName', userPaymail);
        }
      }
    );

    const userTradeInfo = computed(
      () => store.getters['session/userTradeInfo']
    );

    watch(
      () => userTradeInfo.value.pl,
      (newV) => {
        if (newV != 0) {
          setTimeout(() => {
            loading.value = false;
          }, 3000);
        }
      }
    );

    const updateInfo = () => {
      if (updateBlocker.value) {
        return;
      }

      updateBlocker.value = true;
      store.dispatch('session/updateInfo');
      settlementDynamicValue.value = formatMinutes(
        DateTime.fromISO(sessionInfo.value.sessionEnd).diffNow('minutes')
          .minutes
      );
      setTimeout(() => {
        updateBlocker.value = false;
      }, 5000);
    };

    onMounted(() => {
      setTimeout(() => {
        loading.value = false;
      }, 6000);
      onMounted(() => {
        settlementDynamicValue.value = formatMinutes(
          DateTime.fromISO(sessionInfo.value.sessionEnd).diffNow('minutes')
            .minutes
        );
      });
    });

    const nestedItems = ['switchAccount', 'changeTheme', 'changeLanguage'];

    const closeFav = (item) => {
      console.log(item);
      const getItemKey = () => {
        if (nestedItems.includes(item.action)) {
          return item.title;
        } else {
          return item.action;
        }
      };

      store.dispatch('settings/toggleMenuFavouriteItem', getItemKey());
    };

    return {
      settlementDynamicValue,
      updateInfo,
      closeFav,
      userProviders,
      accountBalance,
      balance,
      bountyBalance,
      risk,
      profitLost,
      unsettled,
      activeConnect,
      bountyProgramAvailable,
      sessionInfo,
      grandTotal,
      userPaymail,
      favouriteItems,
      handleMenuItemClick,
      onChangeTabIndex,
      buttonTitle,
      parseTitle,
      usdBsvFormatAmount,
      loading,
      fixedItems,
      fixedItemsData,
    };
  },
};
</script>
<style lang="scss" scoped>
.account-stats {
  display: flex;
  flex-direction: column;
  padding: 18px 20px 15px 20px;
  position: relative;

  &.isWeb {
    padding: 10px 0 0 0;
  }

  .account-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 30px;
    min-height: 170px;
    width: 100%;

    @media (max-width: 768px) {
      min-height: calc(100vh - 250px);
    }
  }

  &__userName {
    min-height: 22px;
    opacity: 0;
    transition: all 1s ease-in-out;
    font-family: Gotham_Pro_Medium;
    margin-bottom: 55px;
    // text-transform: uppercase;
    font-size: 22px;

    &.show {
      opacity: 1;
    }
  }

  &__inline {
    display: flex;
    align-items: center;
  }

  &__row {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    &.mr15 {
      margin-right: 15px;
    }

    .label {
      font-size: 11px;
      font-family: 'Gotham_Pro';
    }

    .value {
      font-family: Cantarell_Bold;
      font-size: 30px;

      &.profit {
        color: #509f96;
      }

      &.loss {
        color: #de5e56;
      }

      &.xs {
        font-size: 15px;
        margin-top: 2px;
      }
    }
  }

  &__actions {
    width: 100%;
    position: absolute;
    left: 0;
    top: 400px;
    display: flex;
    flex-direction: column;

    &.demo {
      top: 390px;
    }

    &.isWeb {
      position: initial;
      margin-left: -20px;
      width: calc(100% + 40px);
    }
  }

  &__action {
    position: relative;
    width: 100%;
    height: 54px;
    display: flex;
    align-items: center;
    // border-bottom: 1px solid rgb(43, 46, 55);

    &:first-child {
      border-top: 1px solid #c3c3c3;
    }

    .indicator {
      width: 10px;
      height: 10px;
      margin: 0 20px;
      border-radius: 50%;
      border: 1px solid rgb(130, 132, 137);

      &.profit {
        background: #509f96;
        border: 1px solid #509f96;
      }

      &.loss {
        background: #de5e56;
        border: 1px solid #de5e56;
      }
    }

    .label {
      font-size: 15px;
      font-family: 'Gotham_Pro_Medium';
    }

    .close {
      position: absolute;
      right: 20px;
      top: 19px;
    }
  }
}

body.dark {
  .account-stats {
    &__action {
      &:first-child {
        border-top: 1px solid rgb(43, 46, 55);
      }
    }
  }
}
</style>
