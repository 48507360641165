<template>
  <div class="bounty-btn" @click="$emit('onClick')">
    <span v-if="loading">
      <Loader class="bounty-loading" />
    </span>
    <span v-else>
      SEND {{ `${marginLabel} ${fiorinMode ? 'USD' : 'BSV'}` }}</span
    >
  </div>
</template>

<script>
import Loader from '@/components/ui/Loader';
import { toCurrencyFormat } from '@/helpers/utils';

export default {
  components: {
    Loader,
  },
  props: {
    fiorinMode: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    marginBtnValue: {
      required: true,
    },
    margin: {
      type: String,
      default: '',
    },
  },
  computed: {
    marginLabel() {
      return toCurrencyFormat(this.marginBtnValue);
    },
  },
};
</script>

<style lang="scss">
.bounty-btn {
  /* background: rgb(104, 200, 131); */
  background: rgba(81, 199, 115, 0.89);
  color: #fff;
  font-family: Cantarell_Bold;
  /* border-radius: 15px; */
  text-align: center;
  font-weight: 800;
  letter-spacing: 1.16px;
  min-height: 50px;
  line-height: 50px;
  min-width: 150px;
  padding: 0 10px;
  box-sizing: border-box;
  transition: box-shadow 0.2s ease-in;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;

  &--moneybutton {
    background-color: #4872f6;
  }
}

.bounty-loading {
  min-height: 30px;
  position: initial;
  text-align: center;
}

.loader.bounty-loading > div {
  background-color: white;
}

.bounty-btn:hover {
  @media screen and (min-width: 1024px) {
    cursor: pointer;
    box-shadow: 0px 1px 3px 1px #ccc;
  }
}
</style>
