import store from '@/store';
import router from '@/router';
import { scope } from '@/breakpoints';
import { DEFAULT_MARKET_BSV_USD } from '@/config/constants';

export const setActiveMarketFromRoute = () => {
  const currentRoute = router.currentRoute.value;
  const marketRouteName = currentRoute.params.name;
  const marketName = getMarketNameFromRoute(marketRouteName);
  const market =
    store.getters['markets/marketsByName'][marketName] ||
    store.getters['markets/marketsByName'][marketRouteName];

  store.commit('markets/UPDATE_ACTIVE_MARKET', market);
};

export const setRouteFromActiveMarket = async (
  opts = { stopDefault: false }
) => {
  if (
    (!opts.stopDefault && !store.getters['markets/activeMarket']) ||
    !store.getters['markets/activeMarket'].name
  ) {
    const storeMarketName = getMarketNameFromRoute(DEFAULT_MARKET_BSV_USD);
    const market = store.getters['markets/marketsByName'][storeMarketName];

    store.commit('markets/UPDATE_ACTIVE_MARKET', market);
  }

  let activeMarketName = store.getters['markets/activeMarket']?.name;

  if (activeMarketName) {
    const routePath =
      (!scope.isLarge && !scope.isXlarge ? '/chart/' : '/market/') +
      getRouteNameFromMarket(activeMarketName);

    await router.push(routePath);
  }
};

const getMarketNameFromRoute = (routeName) => {
  return routeName?.replace('-', '/');
};

const getRouteNameFromMarket = (marketName) => {
  return marketName?.replace('/', '-');
};
