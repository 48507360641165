<template>
  <Modal stop-mobile-max-height to-top @close="onClose">
    <Card
      :body-class="'card-full'"
      :title="$t('bountyModal.get').toLowerCase()"
      class="card"
    >
      <div class="bounty-modal">
        <p>{{ $t('bountyModal.free') }}</p>
        <SimpleInput
          :value="code"
          :class="{ 'has-value': code }"
          type="text"
          :title="$t('popups.phoneVerification.codeBounty')"
          @onChange="onChangeCode"
        />
        <p>{{ $t('bountyModal.tasks') }}</p>
        <div class="bounty-modal__issue">
          <div class="check">
            <icon
              v-if="bountyTasks.firstOrder"
              name="check"
              :width="12"
              :height="12"
              color="#2c9b3f"
            />
          </div>
          <div
            class="label-description"
            v-html="$t('bountyModal.get1dollar')"
          />
        </div>
        <div class="bounty-modal__issue">
          <div class="check">
            <icon
              v-if="bountyTasks.nthOrder"
              name="check"
              :width="12"
              :height="12"
              color="#2c9b3f"
            />
          </div>
          <div
            class="label-description"
            v-html="$t('bountyModal.get2dollar')"
          />
        </div>
        <!-- sharing -->
        <div class="bounty-modal__issue last">
          <div class="check">
            <icon
              v-if="bountyTasks.sharePosition"
              name="check"
              :width="12"
              :height="12"
              color="#2c9b3f"
            />
          </div>
          <div
            class="label-description"
            v-html="$t('bountySharing.bountySharing')"
          />
        </div>
        <!-- <i18n-t keypath="bountyModal.fiorinFee" tag="p">
          <template v-slot:fiorin>
            <span @click="openFiorin" class="underline">
              {{ $t('bountyModal.fiorin') }}
            </span>
          </template>
        </i18n-t>-->
        <div v-html="$t('bountyModal.important')" style="margin-bottom: 10px" />
        <!-- <i18n-t
          v-if="!bountyTasks.start"
          keypath="bountyModal.important"
          tag="p"
        >
          <template v-slot:terms>
            <span
              @click="showModal(modalsByName.termsAndConditions)"
              class="underline"
            >
              {{ $t('footer.terms') }}
            </span>
          </template>
        </i18n-t>-->
      </div>
    </Card>
    <Button
      v-if="code"
      :text="$t('popups.emailVerify.titles.sendCodeBtn')"
      type="secondary"
      class="bounty-btn"
      :class="{
        standalone: wd.navigator.standalone || isTelegram,
      }"
      :disabled="codeIsSending"
      @click="sendCode"
    />
    <Button
      v-if="!code && !bountyTasks.start"
      :text="$t('bountyModal.getStart')"
      :loading="startLoading"
      type="secondary"
      class="bounty-btn"
      :class="{
        standalone: wd.navigator.standalone || isTelegram,
      }"
      @click="getStart"
    />
    <div
      v-else-if="!code"
      :class="{
        standalone: wd.navigator.standalone || isTelegram,
      }"
      class="btns"
    >
      <a
        href="https://faq.dxs.app/getting-started/bounty-trading"
        rel="noopener noreferrer"
        target="_blank"
      >
        <Button
          :text="$t('popups.buttons.learnMore')"
          type="secondary"
          class="btn"
        />
      </a>
    </div>
  </Modal>
</template>
<script>
import Modal from '@/modules/modals/Modal';
import Card from '@/components/ui/Card';
import Button from '@/components/ui/Button';
import SimpleInput from '@/components/ui/SimpleInput';
import '@/assets/icons/check';
import { useStore } from 'vuex';
import { useModals } from '@/modules/modals/api';
import { computed, onMounted, ref } from 'vue';
import { connApp, getErrorDetails } from '@/helpers/signalR';
import notify from '@/plugins/notify';
import { useI18n } from 'vue-i18n';
// import { updateAndSetUserSettings } from '@/modules/settings/api';

const EVENTS = {
  close: 'close',
};

export default {
  components: { Modal, Card, Button, SimpleInput },
  emits: [EVENTS.close],
  setup(_, { emit }) {
    const store = useStore();
    const startLoading = ref(false);
    const { t } = useI18n();
    const { showModal, modalsByName } = useModals();

    const bountyTasks = computed(() => store.getters['bounty/bountyTasks']);

    const authInfo = computed(() => store.getters['user/truedexAuth']);

    const isTelegram = computed(() =>
      Boolean(store.getters['telegram/instance'])
    );

    const onClose = async () => {
      // await updateAndSetUserSettings({
      //   uiSettings: {
      //     bountyPaused: false,
      //   },
      // });

      // await store.dispatch('bounty/updateBountyTasks');
      await store.dispatch('bounty/updateBalance');

      emit(EVENTS.close);
    };

    const openFiorin = () => {
      const frame = document.getElementById('fiorinFrame');

      frame.setAttribute('width', '100%');
      frame.setAttribute('height', '100%');
      frame.setAttribute(
        'style',
        'position: fixed; top: 0; left: 0; z-index: 100100;'
      );
    };

    const stopBountyToNewUsers = ref(false);

    onMounted(async () => {
      if (store.getters['settings/uiSettings']?.mustFinishTwitter) {
        showModal(modalsByName.bountySharing);
        emit(EVENTS.close);
        return;
      }

      if (authInfo?.value?.userId) {
        await store.dispatch('bounty/updateBountyTasks');
        store.dispatch('bounty/updateBalance');
      }
    });

    const getStart = async () => {
      if (stopBountyToNewUsers.value) {
        notify({ text: t('bountyModal.later'), type: 'info' });
      } else {
        startLoading.value = true;
        await connApp
          .invoke('StartBountyProgram')
          .then(() => {
            notify({
              text: t('bountyModal.incoming'),
              type: 'info',
              group: 'bounty',
            });
          })
          .catch((err) => {
            notify({ text: getErrorDetails(err).message, type: 'info' });
            startLoading.value = false;
          });

        store.dispatch('bounty/updateBountyTasks');
        startLoading.value = false;
        localStorage.setItem('bountyStarted', true);
      }
      onClose();
    };

    const code = ref('');
    const codeIsSending = ref(false);

    const sendCode = async () => {
      try {
        codeIsSending.value = true;

        await connApp.invoke('ProcessBountyCode', code.value);

        notify({
          text: 'Bounty received',
          type: 'info',
        });

        await store.dispatch('bounty/updateBountyTasks');
      } catch (err) {
        code.value = '';

        const error = getErrorDetails(err);

        if (error.message === 'Blocked (not new user)') {
          notify({
            text: 'Only your first account has bounty',
            type: 'info',
          });
        } else {
          notify({
            text: error.message,
            type: 'info',
          });
        }
      } finally {
        codeIsSending.value = false;
        code.value = '';
      }
    };

    const onChangeCode = (val) => {
      code.value = val;
    };

    return {
      isTelegram,
      sendCode,
      onChangeCode,
      code,
      codeIsSending,
      getStart,
      onClose,
      startLoading,
      bountyTasks,
      EVENTS,
      showModal,
      modalsByName,
      openFiorin,
      wd: window,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/colors';
@import '@/assets/styles/base';

.bounty-modal {
  height: auto;
  max-height: calc(100% - 115px);
  overflow-y: auto;
  margin-bottom: 45px;
  padding: 0 25px 0 20px;
  box-sizing: border-box;
  font-family: Gotham_Pro_Regular;
  line-height: 20px;

  @media screen and (min-width: 1024px) {
    margin-bottom: 66px;
  }

  div {
    margin-top: 25px;

    h4 {
      font-family: Gotham_Pro_Bold !important;
    }
  }

  &__issue {
    display: flex;
    align-items: center;
    margin-top: 10px;

    &.last {
      margin-bottom: 20px;
    }

    .check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      border-radius: 4px;
      margin-top: 0;
      border: 1px solid #ccc;
    }

    .label-description {
      margin-top: 1px;
      margin-left: 10px;
    }
  }

  p {
    &.underline {
      text-decoration-line: underline;
    }
  }

  span {
    text-decoration-line: underline;
    cursor: pointer;
  }
}

.btns {
  width: 100%;
  margin-top: 30px;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 1024px) {
    bottom: 50px;

    &.standalone {
      bottom: 82px;
    }
  }

  .btn {
    width: 100%;
  }

  a {
    width: 100%;
    text-decoration: none;
  }
}

.bounty-btn {
  margin-top: 30px;
  position: absolute;
  text-transform: initial;
  bottom: 0;
  left: 0;
  width: 100%;

  @media screen and (max-width: 1024px) {
    bottom: 50px;

    &.standalone {
      bottom: 82px;
    }
  }
}
</style>
