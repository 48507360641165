<template>
  <button
    :class="{
      'btn--loading': loading,
      'btn--disabled': disabled,
      'btn--small': size === 'small',
      'btn--mobile-small': size === 'mobile-small',
      'btn--big': size === 'big',
      'btn--wide': width === 'full',
      'btn--round': type === 'round',
      'btn--trading': type === 'trading',
      'btn--secondary': type === 'secondary',
      'btn--buy': type === 'buy',
      'btn--sell': type === 'sell',
      'btn--faded': type === 'faded',
      'btn--optional': type === 'optional',
      'btn--hover': isActive,
      maxWidth,
      backButton,
      isLoginBtn,
      isHtml,
      fixState,
      accent,
    }"
    :disabled="disabled"
    @touchstart="onTouchStart"
    @touchend="onTouchEnd"
    class="btn btn-blue"
  >
    <Loader v-if="loading" color="black" />
    <span
      v-else
      class="flex-row"
      :style="{
        'padding-left': paddingLeft ? paddingLeft + 'px' : 0,
        'padding-right': paddingRight ? paddingRight + 'px' : 0,
      }"
    >
      <slot />
      <span v-if="!isHtml">{{ text }}</span>
      <span v-else v-html="text" />
    </span>
  </button>
</template>

<script>
import Loader from './Loader';
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'Button',
  components: {
    Loader,
  },
  props: {
    /** Sets button text
     */
    text: {
      type: String,
      default: '',
    },
    /** Sets disabled state
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /** Sets loading state
     */
    loading: {
      type: Boolean,
      default: false,
    },
    /** Sets size state
     */
    size: {
      type: String,
      default: 'normal',
    },
    /** Sets width state
     */
    width: {
      type: String,
      default: 'standard',
    },
    /** Sets loading state
     */
    type: {
      type: String,
      default: 'primary',
    },
    paddingLeft: {
      type: String,
      default: '',
    },
    paddingRight: {
      type: String,
      default: '',
    },
    isHtml: {
      type: Boolean,
      default: false,
    },
    isLoginBtn: {
      type: Boolean,
      default: false,
    },
    maxWidth: {
      type: Boolean,
      default: false,
    },
    backButton: {
      type: Boolean,
      default: false,
    },
    fixState: {
      type: Boolean,
      default: false,
    },
    accent: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const isActive = ref(false);

    const onTouchStart = () => {
      isActive.value = true;
    };

    const onTouchEnd = () => {
      isActive.value = false;
    };

    return { onTouchStart, onTouchEnd, isActive };
  },
});
</script>

<style lang="scss">
.btn {
  font-family: Gotham_Pro_Medium;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  flex-basis: 0;
  min-height: 50px;
  width: 186px;

  &.maxWidth {
    width: 100%;
  }

  &.backButton {
    width: 100%;
    font-size: 16px;
    font-family: 'Gotham_Pro_Regular';
    line-height: 50px;
    max-height: 50px;
    padding-top: 3px;
    height: 50px;
    background-color: #121722 !important;
    color: #808080 !important;

    span {
      color: #808080 !important;
    }

    &:hover {
      background-color: #121722 !important;
    }
  }

  .flex-row {
    display: flex;
    align-items: center;
  }

  transition: background-color 0.6s, opacity 0.2s;

  &:hover {
  }
  &:focus {
    outline: none;
  }
}
.btn--disabled {
  &.btn--buy {
    font-family: Cantarell_Regular;
    color: rgba(0, 0, 0, 0.5);

    &:hover {
    }
  }
  &.btn--sell {
    font-family: Cantarell_Regular;
    color: rgba(0, 0, 0, 0.5);

    &:hover {
    }
  }
}
.btn--small {
  height: 2rem;
  padding: 0 0.76rem;
  min-width: 6.8889rem;
  font-size: 0.9rem;
  letter-spacing: -0.6px;
}
.btn--mobile-small {
  letter-spacing: -0.75px;
  font-size: 0.9375rem;
  font-family: Gotham_Pro;
  height: 3rem;
  padding: 0;
  width: 6.75rem;
}
.btn--big {
}
.btn--trading {
  background-color: rgb(251, 241, 80);
  border-radius: 5px;
}
.btn--loading {
}
.btn--wide {
}
.btn--round {
}
.btn--secondary {
  width: 186px;
  height: 60px;
  // padding-top: 3px;
  padding-bottom: 1px;
  border: solid;
  border-width: 1px;
  opacity: 0.8;
  font-size: 16px; //0.9375rem;
  background-color: rgb(205, 203, 203);
  border-color: rgba(0, 0, 0, 0.1);
  &.btn--disabled {
    border-color: rgba(0, 0, 0, 0.1);
  }
  &.isHtml {
    padding-top: 3px;
  }
}
.btn:hover {
  cursor: pointer;
}
.btn--secondary:hover {
  opacity: 0.6;
}

.btn--wide.btn--secondary.fixState.btn.btn-blue.login-button {
  background-color: #ffffff;
  color: #353535;
  opacity: 0.6;
}

.dark button.btn--wide.btn--secondary.fixState span,
.dark .btn.fixState span {
  color: #353535;
}

// accent button
// .dark {
.btn--secondary.accent.btn.btn-blue:not(.login-button) {
  background: #fff;
  opacity: 1;

  &:hover {
    opacity: 0.8;
  }
}
.btn--secondary.accent.btn.btn-blue:not(.login-button) span {
  color: #000;
}
// }

.btn.btn--optional {
  color: #888;
  font-size: 13px !important;
  background-color: transparent;
  transition: color 0.2s ease-in;
  border-top: none;
}
.btn.btn--optional:hover {
  color: #fff;
  background-color: transparent;
  opacity: 1;
}

@media screen and (max-width: 1023px) {
  .btn--secondary:hover {
    opacity: 0.8;
  }
}
.btn--buy {
  &:hover {
  }
}
.btn--sell {
  &:hover {
  }
}
.btn--faded {
  &:hover {
  }
}

.btn--sell,
.btn--buy {
  &:hover {
    opacity: 0.8;
  }
}

.spinner {
  margin-right: 10px;
}

.contest-btn {
  margin-bottom: 0.25rem;
  white-space: nowrap;
  font-size: 0.9375rem;
  height: 30px;
  min-height: 30px;
  max-height: 30px;
  // line-height: 30px;
  margin-top: 0.25rem;
  width: 100%;
}

.isLoginBtn {
  text-transform: initial;
  font-family: 'Gotham_Pro_Bold';
  font-size: 0.9375rem;
}

.btn.btn-blue.isLoginBtn {
  // background: #509f96;
  span {
    // color: #fff;
  }
}

body.light {
  .btn.btn-blue.isLoginBtn {
    // background: rgb(29, 29, 29);
  }
}

body.light {
  .btn {
    &.backButton {
      background-color: #fff !important;
    }
  }
  .btn.btn-blue.isLoginBtn {
    // background: rgb(29, 29, 29);
    span {
      // color: #fff;
    }

    &:hover {
      // background: rgb(154, 154, 154);
    }
  }
}

body.twilight {
  .btn.btn-blue.isLoginBtn {
    // background: rgb(90, 103, 241);

    &:hover {
      // background: #fff;

      span {
        // color: #000;
      }
    }
  }
}
</style>
