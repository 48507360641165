<template>
  <teleport v-if="isMounted" to="#modal-container" :disabled="!teleport">
    <div
      :style="{ zIndex }"
      class="modal"
      :class="[modalClass, { corner, historyMode }]"
      @click="closeOutside && modalClick($event)"
    >
      <div
        :class="[
          bodyClass,
          {
            stopMobileMaxHeight,
            toTop,
            historyMode,
            standalone:
              wd.navigator.standalone ||
              isRunningStandaloneAndroid() ||
              isTelegram,
            hideBackInMini,
            toBottom: !showBackControls,
          },
        ]"
        :style="{
          'max-height': width !== '375px' || width !== 'auto' ? 'unset' : '',
          width: actualWidth,
          ...bodyStyle,
        }"
      >
        <FavouriteStar
          v-if="activeMarket?.name"
          :active-market="activeMarket"
          class="star-wrap"
          @click.stop="() => {}"
        />
        <div
          v-if="!historyMode && !hideClose"
          :class="{ inChart }"
          class="close-wrap"
          @click="close"
        >
          <icon
            v-if="closeInside"
            name="cancel"
            :width="12"
            :height="12"
            class="close"
          />
        </div>
        <slot />
      </div>
      <div
        v-if="showBackControls"
        :class="{
          hideBackInMini,
          standalone:
            wd.navigator.standalone ||
            isRunningStandaloneAndroid() ||
            isTelegram,
          hideMobileBack,
        }"
        class="modal__back"
        @click="onBack"
      >
        <Button max-width back-button text="BACK" @click="$emit('close')" />
      </div>
      <div
        v-if="
          (showBackControls && wd.navigator.standalone) ||
          isRunningStandaloneAndroid() ||
          isTelegram
        "
        class="modal__back-panel"
      />
    </div>
  </teleport>
</template>
<script>
import { computed, ref, onMounted, onBeforeUnmount } from 'vue';
import '@/assets/icons/cancel';
import isString from 'lodash/isString';
import { useScope } from '@/compositions/useScope';
import { MODAL_DEFAULT_Z_INDEX } from '@/helpers/enums';
import { isRunningStandaloneAndroid } from '@/helpers/detects';
import FavouriteStar from '@/components/ui/FavouriteStar';
import Button from '@/components/ui/Button';
import { useStore } from 'vuex';

export default {
  name: 'Modal',
  components: {
    FavouriteStar,
    Button,
  },
  emits: ['close'],
  props: {
    hideBackInMini: {
      type: Boolean,
      default: false,
    },
    hideMobileBack: {
      type: Boolean,
      default: false,
    },
    inChart: {
      type: Boolean,
      default: false,
    },
    activeMarket: {
      type: [Object, String],
      default: '',
    },
    hideClose: {
      type: Boolean,
      default: false,
    },
    corner: {
      type: Boolean,
      default: false,
    },
    closeOutside: {
      type: Boolean,
      default: true,
    },
    closeInside: {
      type: Boolean,
      default: true,
    },
    modalClass: {
      type: String,
      default: '',
    },
    stopMobileMaxHeight: {
      type: Boolean,
      default: false,
    },
    historyMode: {
      type: Boolean,
      default: false,
    },
    toTop: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: '100%',
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    bodyStyle: {
      type: Object,
      default: () => ({}),
    },
    teleport: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const { isNotDesktop } = useScope();
    const store = useStore();

    const bodyClass = 'modal__body';
    const zIndex = ref(20);
    const isMounted = ref(false);
    const modalBody = ref(null);
    const close = () => {
      emit('close');
    };

    onBeforeUnmount(() => {
      const zindex = window.document.body.clientWidth >= 1024 ? 922 : 923;

      if (zIndex.value === zindex - 1) {
        document.body.style.overflow = '';
      }
    });

    const onBack = () => {
      if (document.querySelector('.landing-dxs')) {
        document.querySelector('.landing-dxs').classList.remove('show');
      }
    };

    onMounted(() => {
      document.body.style.overflow = 'hidden';
      zIndex.value +=
        document.querySelectorAll('.modal').length + MODAL_DEFAULT_Z_INDEX;

      isMounted.value = true;
    });

    const actualWidth = computed(() => {
      return props.width && props.width !== '100%'
        ? props.width
        : props.fullWidth
        ? '100%'
        : '';
    });

    const modalClick = (event) => {
      const path = event.path || (event.composedPath && event.composedPath());
      const pathThroughClasses = path.map((node) => node.className);
      const hasBodyClass = pathThroughClasses
        .filter(isString)
        .some((cls = '') => cls.includes(bodyClass));

      if (hasBodyClass) {
        return;
      }

      close();
    };

    const showBackControls = computed(
      () => store.getters['localUiSettings/showBackControls']
    );

    const isTelegram = computed(() =>
      Boolean(store.getters['telegram/instance'])
    );

    return {
      showBackControls,
      zIndex,
      close,
      onBack,
      modalBody,
      bodyClass,
      actualWidth,
      modalClick,
      isNotDesktop,
      wd: window,
      isRunningStandaloneAndroid,
      isMounted,
      isTelegram,
    };
  },
};
</script>
<style lang="scss" scoped>
.modal {
  position: fixed;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.8);

  &__back-panel {
    position: absolute;
    background: #fff;
    width: 100%;
    height: 32px;
    left: 0;
    bottom: 0;
  }

  &__back {
    @media (min-width: 1024px) {
      display: none;
    }

    &.hideBackInMini {
      @media (max-width: 340px) {
        display: none;
      }
    }

    &.hideMobileBack {
      display: none;
    }
  }

  &.historyMode {
    background: rgba(0, 0, 0, 1);
  }

  &.corner {
    &::after {
      content: '';
      position: absolute;
      bottom: -15px;
      left: calc(50% - 20px);
      border: 20px solid transparent;
      border-top: 20px solid #121722;
    }
  }

  &::v-deep .btn {
    &-wrap {
      position: relative;
      bottom: 50px;
      display: flex;
    }
  }

  &.icon--black {
    ::v-deep {
      svg g {
        fill: black;
      }
    }
  }

  .star-wrap {
    padding: 12px;
    width: 40px;
    height: 40px;
    position: absolute;
    right: 40px;
    top: 10px;
    z-index: 11;
  }

  .close-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    right: -4px; // 0; // 5//0
    // top: 3px;
    top: 0;
    z-index: 10;
    cursor: pointer;
    position: absolute;
    padding: 12px;
    width: 40px;
    height: 40px;

    @media screen and (min-width: 1024px) {
      right: 19px;
      top: 13px;
    }

    //
    padding: 20px;
    width: 60px;
    height: 60px;
  }

  &.stickToUp {
    justify-content: flex-start;
    padding-top: 50px;
  }

  &.chart-modal {
    .modal__body {
      margin-bottom: 48px;
      overflow-x: hidden;

      &.standalone {
        margin-bottom: 52px; // 38px;

        // margin-bottom: 63px;
      }

      &.hideBackInMini {
        @media (max-width: 340px) {
          margin-bottom: 0;
        }
      }

      &.toBottom {
        margin-bottom: 0;
      }
    }
  }

  &:not(.landing-modal):not(.widget-modal):not(.chart-modal) {
    .modal__body,
    .modal__back {
      overflow: hidden;
      min-width: 375px;
      @media screen and (min-width: 1024px) {
        min-width: 420px;
      }
      @media screen and (min-width: 641px) {
        max-width: 375px;
      }

      @media screen and (max-width: 640px) {
        max-width: 100%;
      }

      @media screen and (max-width: 375px) {
        min-width: 100%;
      }
    }
  }

  &.landing-modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .close {
      color: #000;
      width: 25px;
      height: 25px;

      @media screen and (max-width: 1024px) {
        width: 20px;
        height: 20px;
      }
    }
    .modal__body {
      height: auto;

      @media (max-width: 1024px) {
        max-width: 90%;
      }

      @media (max-width: 768px) {
        max-width: 100%;
      }
    }

    .modal__back {
      @media (max-width: 1024px) {
        max-width: 90%;
      }

      @media (max-width: 768px) {
        max-width: 100%;
      }
    }
  }

  &.mobile-app {
    .close {
      width: 20px;
      height: 20px;
      top: 15px;
    }
  }

  &.widget-modal {
    .close {
      top: 12px;
      width: 15px;
      height: 15px;
      color: #fff;
      fill: #fff;
      right: 15px;
      opacity: 1;
    }

    .modal__body {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 45px;
      margin-bottom: 0;
      overflow: visible;
    }
  }

  &._align-bottom {
    align-items: flex-end;
  }

  &__back {
    width: 100%;
    height: 50px;
    max-height: 50px;
    bottom: 0;
    background-color: #fff;
    box-sizing: border-box;
    // padding: 1px;

    &.standalone {
      height: 50px;
      max-height: 50px;

      bottom: 32px;
    }

    @media screen and (max-width: 1024px) {
      // display: none;
      position: absolute;
    }
  }

  &__body {
    position: relative;
    max-height: 650px;
    overflow: auto;

    @media screen and (max-width: 1024px) {
      height: 100%;
      max-height: unset;

      &.stopMobileMaxHeight {
        height: auto;
        margin-bottom: 0;
        position: fixed;
        bottom: 50px;

        &.standalone {
          margin-bottom: 32px; // 10px;
        }

        &.toTop {
          bottom: 0; // auto (before back button)
          top: 0;

          &.standalone {
            margin-bottom: 0px;
          }
        }

        &.historyMode {
          top: 0; // 20px;
          margin-bottom: 0; // 20px;
        }
      }
    }
  }

  .close {
    z-index: 10000;
    // position: absolute;
    right: 16px;
    top: 15px;
    opacity: 0.7;

    &:hover {
      cursor: pointer;
      opacity: 1;
    }
  }
}

@media (max-width: 480px) {
  .modal {
    align-items: flex-end;

    &.stickToUp {
      align-items: flex-start;
      padding-top: 30px;
    }

    &__body,
    &__back {
      width: 100%;
    }

    .close-wrap {
      top: 0px;

      &.inChart {
        top: 1px;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .modal {
    &:not(.landing-modal):not(.widget-modal):not(.chart-modal) {
      .modal__body {
        &.historyMode {
          border-radius: 45px;
          overflow: hidden;
          min-width: 955px;

          @media screen and (min-width: 641px) {
            max-width: 955px;
            max-height: 667px !important;
          }

          + .modal__back {
            min-width: 955px;

            @media screen and (min-width: 641px) {
              max-width: 955px;
              max-height: 667px !important;
            }
          }
        }
      }
    }

    &.historyMode {
      background: #313131b0;
    }
  }
}

// for pwa
.modal.corner {
  .modal__body {
    @media screen and (max-width: 1024px) {
      &.stopMobileMaxHeight {
        margin-bottom: 24px;
      }
    }
  }
}

body.light {
  .modal {
    &.corner {
      &::after {
        border-top: 20px solid #fff;
      }
    }
  }
}

body.twilight {
  .modal {
    &.corner {
      &::after {
        border-top: 20px solid rgb(31, 37, 72);
      }
    }
  }
}

body.dark {
  .modal {
    &__back-panel {
      background: #121722;
    }

    &__back {
      background-color: #121722;
    }
  }
}
</style>
