import { getBountyTasks, getPositionStatus } from '@/modules/bounty/api';
import notify from '@/plugins/notify';
import { getErrorDetails } from '@/helpers/signalR';
const FIORIN_HOST = process.env.VUE_APP_FIORIN_HOST;

const state = {
  balance: 0,
  realBalance: 0,
  bountyTasks: {
    start: false,
    firstOrder: false,
    nthOrder: false,
    paused: false,
    hasPositions: false,
    bountySharing: false,
  },
};

const getters = {
  balance: (state) => state.balance,
  bountyTasks: (state) => state.bountyTasks,
};

const types = {
  SET_BALANCE: 'SET_BALANCE',
  SET_BOUNTY_TASKS: 'SET_BOUNTY_TASKS',
  SET_BOUNTY_PAUSE: 'SET_BOUNTY_PAUSE',
};

const mutations = {
  [types.SET_BALANCE](state, value) {
    state.realBalance = value;

    // if (!state.bountyTasks.paused) {
    state.balance = value;
    // }
  },
  [types.SET_BOUNTY_TASKS](state, { result, paused, hasPositions }) {
    Object.assign(state.bountyTasks, { ...result, paused, hasPositions });
  },
  [types.SET_BOUNTY_PAUSE](state, value) {
    state.bountyTasks.paused = value;

    if (value) {
      state.balance = 0;
    }
  },
};

// let timeouts = [];
const actions = {
  setBountyBalance({ commit }, value) {
    commit(types.SET_BALANCE, value);
  },
  async updateBalance({ dispatch }) {
    await dispatch('updateBountyTasks');
    if (frames.fiorin) {
      frames.fiorin.postMessage({ event: 'GET_BOUNTY_BALANCE' }, FIORIN_HOST);
    }
    // timeouts.forEach((element) => clearTimeout(element));
    // timeouts = [];
    // [2000, 5000, 10000, 20000].forEach((delay) => {
    //   timeouts.push(
    //     setTimeout(async () => {
    //       const result = await getBountyBalance();

    //       if (state.realBalance < result && result === 0.1) {
    //         notify({
    //           text: 'Bounty received',
    //           type: 'info',
    //           group: 'bounty',
    //         });
    //       }

    //       commit(types.SET_BALANCE, result);
    //     }, delay)
    //   );
    // });
  },

  setBountyPaused({ commit }, isPaused) {
    commit(types.SET_BOUNTY_PAUSE, isPaused);
  },

  async updateBountyTasks({ commit, rootGetters }) {
    const paused = rootGetters['settings/uiSettings']?.bountyPaused;
    const result = await getBountyTasks().catch((err) => {
      notify({ text: getErrorDetails(err).message, type: 'info' });
      if (!localStorage.getItem('getBountyTasksError')) {
        console.log(3);
        window.location.reload();
        localStorage.setItem('getBountyTasksError', true);
      }
    });

    const hasPositions = await getPositionStatus();

    if (result) {
      localStorage.removeItem('getBountyTasksError');
      commit(types.SET_BOUNTY_TASKS, { result, paused, hasPositions });
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
