import { getErrorMessage, logMessage } from './helpers';
var QuotesPulseProvider = /** @class */ (function () {
  function QuotesPulseProvider(quotesProvider) {
    this._subscribers = {};
    this._requestsPending = 0;
    this._quotesProvider = quotesProvider;
    setInterval(this._updateQuotes.bind(this, 1 /* Fast */), 10000 /* Fast */);
    setInterval(
      this._updateQuotes.bind(this, 0 /* General */),
      60000 /* General */
    );
  }
  QuotesPulseProvider.prototype.subscribeQuotes = function (
    symbols,
    fastSymbols,
    onRealtimeCallback,
    listenerGuid
  ) {
    this._subscribers[listenerGuid] = {
      symbols: symbols,
      fastSymbols: fastSymbols,
      listener: onRealtimeCallback,
    };
    logMessage('QuotesPulseProvider: subscribed quotes with #' + listenerGuid);
  };
  QuotesPulseProvider.prototype.unsubscribeQuotes = function (listenerGuid) {
    delete this._subscribers[listenerGuid];
    logMessage(
      'QuotesPulseProvider: unsubscribed quotes with #' + listenerGuid
    );
  };
  QuotesPulseProvider.prototype._updateQuotes = function (updateType) {
    var _this = this;
    if (this._requestsPending > 0) {
      return;
    }
    var _loop_1 = function (listenerGuid) {
      this_1._requestsPending++;
      var subscriptionRecord = this_1._subscribers[listenerGuid];
      this_1._quotesProvider
        .getQuotes(
          updateType === 1 /* Fast */
            ? subscriptionRecord.fastSymbols
            : subscriptionRecord.symbols
        )
        .then(function (data) {
          _this._requestsPending--;
          if (
            !Object.prototype.hasOwnProperty.call(
              _this._subscribers,
              listenerGuid
            )
          ) {
            return;
          }
          subscriptionRecord.listener(data);
          logMessage(
            'QuotesPulseProvider: data for #' +
              listenerGuid +
              ' (' +
              updateType +
              ') updated successfully, pending=' +
              _this._requestsPending
          );
        })
        .catch(function (reason) {
          _this._requestsPending--;
          logMessage(
            'QuotesPulseProvider: data for #' +
              listenerGuid +
              ' (' +
              updateType +
              ') updated with error=' +
              getErrorMessage(reason) +
              ', pending=' +
              _this._requestsPending
          );
        });
    };
    var this_1 = this;
    for (var listenerGuid in this._subscribers) {
      _loop_1(listenerGuid);
    }
  };
  return QuotesPulseProvider;
})();
export { QuotesPulseProvider };
