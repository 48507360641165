<template>
  <Modal @close="$emit('close')" stop-mobile-max-height>
    <div class="share-bounty">
      <Card :title="$t('bountySharing.title')" class="card kys-status__inner">
        <div class="share-bounty__content">
          {{ $t('bountySharing.description') }}
        </div>
        <div class="share-bounty__field">
          <SimpleInput
            :value="link"
            type="text"
            size="sm"
            :title="$t('bountySharing.placeholder')"
            @onChange="onChangeLink"
            class="mt-15"
          />
        </div>
        <div
          class="share-bounty__content"
          v-html="$t('bountySharing.important')"
        />
      </Card>
      <div class="share-bounty__btns">
        <Button
          type="secondary"
          width="full"
          :text="$t('bountySharing.btnLater')"
          @click="onLater"
        />
        <Button
          type="secondary"
          width="full"
          :disabled="!link.trim()"
          :text="$t('bountySharing.btnSubmit')"
          @click="onSubmit"
        />
      </div>
    </div>
  </Modal>
</template>
<script>
import Modal from '@/modules/modals/Modal';
import Card from '@/components/ui/Card';
import Button from '@/components/ui/Button';
import SimpleInput from '@/components/ui/SimpleInput';
import { useStore } from 'vuex';
import { defineComponent, ref, computed, onMounted } from 'vue';
import axios from 'axios';
import notify from '@/plugins/notify';
import { updateAndSetUserSettings } from '@/modules/settings/api';
import { userProviders } from '@/modules/user/api';
import get from 'lodash/get';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'ShareBounty',
  components: { Button, Modal, Card, SimpleInput },
  setup(_, { emit }) {
    const { t } = useI18n();
    const store = useStore();
    const link = ref('');

    const activeConnect = computed(
      () => store.getters['connectors/activeConnect']
    );

    const onLater = async () => {
      await updateAndSetUserSettings({
        uiSettings: {
          mustFinishTwitter: true,
        },
      });
      emit('close');
    };

    const onSubmit = async () => {
      axios
        .post(
          `${process.env.VUE_APP_API_URL}bounty/task/share-position`,
          link.value,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${activeConnect.value.accessToken}`,
            },
          }
        )
        .then(async () => {
          notify({
            text: t('bountyModal.incoming'),
            type: 'info',
            group: 'bounty',
          });
          await updateAndSetUserSettings({
            uiSettings: {
              mustFinishTwitter: null,
            },
          });
          emit('close');
        })
        .catch((error) => {
          get(error, 'response.data.error.message', error);
          notify({
            text: get(error, 'response.data.error.message', error),
            type: 'warning',
          });
        });
    };

    const onChangeLink = (value) => {
      link.value = value;
    };

    onMounted(() => {
      if (activeConnect.value.provider !== userProviders.fiorin) {
        emit('close');
      }
    });

    return {
      link,
      onLater,
      onSubmit,
      onChangeLink,
    };
  },
});
</script>

<style lang="scss">
.share-bounty {
  &__inner {
    min-height: 250px;
    padding-bottom: 50px;
  }

  &__content {
    line-height: 1.2;
    position: relative;
    text-align: left;
    font-family: Gotham_Pro_Regular;
  }

  &__field {
    margin: 25px 0;
  }

  &__btns {
    position: absolute;
    display: flex;
    flex-direction: row;
    bottom: 0;
    width: 100%;
  }
}
</style>
