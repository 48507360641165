import { DEFAULT_MARKET_BSV_USD } from '@/config/constants';

export const obsoleteMarkets = [
  'CAC 40',
  'DAX',
  'DXY',
  'TAAL',
  'FTSE 100',
  'Copper',
  'VIX',

  'ATOM/USD',
  'ATOM/BTC',
  'XTZ/BTC',

  'ETC/BTC',
  'XRP/BTC',
  'LTC/BTC',
  'LEO/USD',
  'ETH/BTC',
  'BNB/BTC',
  'BCH/BTC',
  'MIOTA/USD',
  'MIOTA/BTC',
  'NEO/BTC',
  'XMR/BTC',
  'XRP/BTC',
  'Silver/EUR',
  'Gold/EUR',
];

export const cryptoLabelByName = {
  'OP/USD': 'Optimism',
  'INJ/USD': 'Injective',
  'BNB/USD': 'BNB',
  'CRV/USD': 'Curve DAO Token',
  'BSV/USD': 'Bitcoin SV',
  'BSV/BTC': 'Bitcoin SV',
  'BTC/USD': 'Bitcoin Core',
  'AAVE/USD': 'Aave',
  'ADA/USD': 'Cardano',
  'ALGO/USD': 'Algorand',
  'AVAX/USD': 'Avalanche',
  'AXS/USD': 'Axie Infinity',
  'BCH/USD': 'Bitcoin Cash',
  'CAKE/USD': 'PancakeSwap',
  'CHZ/USD': 'Chiliz',
  'CRO/USD': 'Cronos',
  'DASH/USD': 'Dash',
  'DOGE/USD': 'Dogecoin',
  'DOT/USD': 'Polkadot',
  'EGLD/USD': 'Elrond',
  'ENJ/USD': 'Enjin Coin',
  'EOS/USD': 'EOS',
  'EOS/BTC': 'EOS',
  'ETC/USD': 'Ethereum Classic',
  'ETH/USD': 'Ethereum',
  'FIL/USD': 'Filecoin',
  'FTM/USD': 'Fantom',
  'FTT/USD': 'FTX Token',
  'GRT/USD': 'Graph',
  'HBAR/USD': 'Hedera',
  'ICP/USD': 'Internet Computer',
  'IOTA/USD': 'IOTA',
  'KSM/USD': 'Kusama',
  'LINK/USD': 'Chainlink',
  'LTC/USD': 'Litecoin',
  'MATIC/USD': 'Polygon',
  'NEAR/USD': 'NEAR Protocol',
  'NEO/USD': 'Neo',
  'QNT/USD': 'Quant',
  'SC/USD': 'Siacoin',
  'SHIB/USD': 'Shiba Inu',
  'SNX/USD': 'Synthetix',
  'SOL/USD': 'Solana',
  'SUSHI/USD': 'SushiSwap',
  'THETA/USD': 'Theta',
  'TRX/USD': 'Tron',
  'TRB/USD': 'Tellor',
  'TIA/USD': 'Celestia',
  'UNI/USD': 'Uniswap',
  'VET/USD': 'VeChain',
  'XEC/USD': 'eCash',
  'XLR/USD': 'Stellar',
  'XMR/USD': 'Monero',
  'XRP/USD': 'Ripple',
  'XTZ/USD': 'Tezos',
  'ZIL/USD': 'Ziliqa',
  'APT/USD': 'Aptos',
  'SUI/USD': 'Sui',
  'LDO/USD': 'Lido DAO',
  'PEPE/USD': 'PEPE',
  'ARB/USD': 'Arbitrum',
  'DYDX/USD': 'dYdX',
  'ORDI/USD': 'Ordinals',
};

export const marketSymbolByName = {
  Copper: 'HG',
  'Crude Oil WTI': 'WTICO',
  Gold: 'XAU',
  'Gold/Silver': 'XAU/XAG',
  'Natural Gas': 'NATGAS',
  Palladium: 'XPD',
  Platinum: 'XPT',
  Silver: 'XAG',
  'US Corn': 'ZC',
  'US Wheat': 'ZW',
  'Activision Blizzard': 'ATVI',
  Airbnb: 'ABNB',
  Alibaba: 'BABA',
  Alphabet: 'GOOGL',
  Amazon: 'AMZN',
  AMC: 'AMC',
  AMD: 'AMD',
  Apple: 'AAPL',
  AstraZeneca: 'AZN',
  'Bank of America': 'BAC',
  'Beam Therapeutics': 'BEAM',
  'Beyond Meat': 'BYND',
  BlackBerry: 'BB',
  Boeing: 'BA',
  Cisco: 'CSCO',
  Citigroup: 'C',
  'Coca-Cola': 'COCACOLA',
  Coinbase: 'COIN',
  'Digital Turbine': 'APPS',
  Disney: 'DIS',
  DraftKings: 'DKNG',
  Dropbox: 'DBX',
  'Electronic Arts': 'EA',
  'Exxon Mobil': 'EXXON',
  Facebook: 'FB',
  Farfetch: 'FTCH',
  FedEx: 'FDX',
  'Freeport-McMoRan': 'FCX',
  Gamestop: 'GME',
  'General Electric': 'GE',
  'General Motors': 'GM',
  'Gilead Sciences': 'GILD',
  'Goldman Sachs': 'GS',
  'Hecla Mining': 'HL',
  IBM: 'IBM',
  Illumina: 'ILMN',
  Intel: 'INTC',
  'JPMorgan Chase': 'JPM',
  'Lithium Americas': 'LAC',
  Marvell: 'MRVL',
  MasterCard: 'MA',
  Microsoft: 'MSFT',
  Microstrategy: 'MSTR',
  Moderna: 'MRNA',
  'Morgan Stanley': 'MS',
  Mosaic: 'MOS',
  'NRG Energy': 'NRG',
  NVIDIA: 'NVDA',
  Netflix: 'NFLX',
  'Newmont Goldcorp': 'NEM',
  Nike: 'NKE',
  Nokia: 'NOK',
  Nordstrom: 'JWN',
  NortonLifeLock: 'NLOK',
  Paypal: 'PYPL',
  Pfizer: 'PFE',
  'Philip Morris': 'PM',
  'Plug Power': 'PLUG',
  Raytheon: 'RTN',
  'Rio Tinto': 'RIO',
  'Riot Blockchain': 'RIOT',
  Roblox: 'RBLX',
  Salesforce: 'CRM',
  Snapchat: 'SNAP',
  Sofi: 'SOFI',
  'Southern Copper': 'SCCO',
  Stronghold: 'SDIG',
  Square: 'SQ',
  Starbucks: 'SBUX',
  'T-Mobile': 'TMUS',
  Tesla: 'TSLA',
  'Trade Desk': 'TTD',
  Twitter: 'TWTR',
  Uber: 'UBER',
  'Under Armour': 'UAA',
  Visa: 'V',
  'Wells Fargo': 'WFC',
  'Xerox Corp': 'XRX',
  'Zoom Video': 'ZM',
  'China A50': 'CN50',
  'Dow Jones 30': 'DOW30',
  'FTSE 100': 'UK100',
  'German 30 DAX': 'DAX',
  'Hong Kong 33': 'HK33',
  'Nasdaq 100': 'NDX',
  'Nifty 50': 'IN50',
  'Nikkei 225': 'JP225',
  'Russell 2000': 'US2000',
  'Russell 2000 ETF': 'IWM',
  'S&P 500': 'SPX500',
  'S&P 500 ETF': 'SPY',
  'SMH ETF': 'SMH',
  TLT: 'TLT',
};

export const createEntityFromMarket = (market) => ({
  ...market,
  ticker: market.name.split('/')[0],
  base: market.name.split('/')[1] || '',
  usdPrice: (market.buy + market.sell) / 2,
});

export const createEntityFromStatus = (status) => ({
  is_work_time: status.state == 'Open',
  until: status.until || '24/7',
});

export const sortMarkets = (markets) => {
  return [...markets].sort((a, b) => {
    if (a.group_order == 2) {
      // if (a.group_order != undefined) {
      if (b.group_order == 2) {
        // if (b.group_order != undefined) {
        return a.group_order - b.group_order;
      } else {
        return -1;
      }
    } else if (b.group_order == 2) {
      // } else if (b.group_order != undefined) {
      return 1;
    } else {
      return a.ticker && a.ticker.localeCompare(b.ticker);
    }
  });
};

export const DEFAULT_MARKET_PAIR = DEFAULT_MARKET_BSV_USD;
